import React from 'react'
import "../../../css/bootstrap.scss"
import "../../../css/component.scss"
import '../../../css/faq-accordion.scss'
import Tabs from '../../tabs/Tabs';

function ShopperRoiPages({ activeButton, scrollToDiv }) {
    
    return (
        <div className='bootstrap-content'>
            <div className="row justify-content-center gx-0">
                <div className="col-12 col-lg-10 container-div">
                    <div className="layer-container">
                        <Tabs activeButton={activeButton} scrollToDiv={scrollToDiv} />
                        <div className='tab-content-div'>
                            <div className="banner-section">
                                <div className="row g-lg-5 align-items-center">
                                    <div className="col-12 col-lg-7 content-div">
                                        <h1 className='heading'>This is how it works</h1>
                                        <h6 className='sub-heading'>1. Omnichannel ROAS Exploration</h6>
                                        <div className="sub-content-div">
                                            <p>Introducing Shopper ROI: The ultimate navigation system for your entire shopper marketing ecosystem. Our Connected Intelligence platform seamlessly integrates data streams from offline shopper activities, retail media networks, and coupon redemptions, creating a comprehensive map of your Shopper marketing performance.</p>
                                            <p>Powered by advanced Causal AI algorithms, we reveal the true impact of each channel on your overall Return on Ad Spend (ROAS).</p>
                                            <p>This enables you to:</p>
                                            <ul className='list-style-dot'>
                                                <li><p>Navigate with data-driven precision</p></li>
                                                <li><p>Optimize your marketing mix</p></li>
                                                <li><p>Maximize ROI across all touchpoints in your shopper's journey</p></li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-12 col-lg-5 text-lg-end image-div">
                                        <img src={require('../../../assets/images/shopper-roi/9.jpg')} alt="Omnichannel ROAS Exploration" className='frames-image' />
                                    </div>
                                </div>
                            </div>
                            <div className="banner-section">
                                <div className="row g-lg-5 align-items-center">
                                    <div className="col-12 col-lg-5 image-div">
                                        <img src={require('../../../assets/images/shopper-roi/10.jpg')} alt="Bridging Online and Offline Shopper Marketing" className='frames-image' />
                                    </div>
                                    <div className="col-12 col-lg-7 content-div">
                                        <h6 className='sub-heading'>2. Bridging Online and Offline Shopper Marketing</h6>
                                        <div className="sub-content-div">
                                            <p className='mb-0'>Our Connected Intelligence tool evaluates marketing effectiveness across all channels, tracking:</p>
                                            <ul className="list-style-dot">
                                                <li><p>Customer behaviors</p></li>
                                                <li><p>Purchase patterns</p></li>
                                                <li><p>Segment responsiveness</p></li>
                                            </ul>
                                            <p>Measure the pull of in-store promotions, demos, and sampling events.</p>
                                            <p>Optimize strategies for maximum ROI across retail.</p>
                                            <p>Integrates online and offline data</p>
                                            <p className='mb-0'>Provides :</p>
                                            <ul className="list-style-dot">
                                                <li><p>Actionable insights to enhance targeting precision</p></li>
                                                <li><p>Optimized resource allocation across channels</p></li>
                                                <li><p>Improved campaign performance and shopper engagement</p></li>
                                                <li><p>Boosted sales across all retail dimensions</p></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="banner-section">
                                <div className="row g-lg-5 align-items-center">
                                    <div className="col-12 col-lg-7 content-div">
                                        <h6 className='sub-heading'>3. Retail Media Halo Effect (Mapping Amazon/RMN ROI on Offline Sales)</h6>
                                        <div className="sub-content-div">
                                            <p>The AI Cockpit quantifies how online retail media influences offline sales, tracking ROI across major platforms in the retail universe (Amazon, Walmart, Target, Kroger, other RMNs).</p>
                                            <p>Features:</p>
                                            <ul className='list-style-dot'>
                                                <li>
                                                    <p>Holistic campaign performance visualization</p>
                                                </li>
                                                <li>
                                                    <p>Precise measurement of online influence on in-store purchases</p>
                                                </li>
                                                <li>
                                                    <p>Actionable insights for optimal media spend allocation</p>
                                                </li>

                                            </ul>
                                            <p>Benefits:</p>
                                            <ul className='list-style-dot'>
                                                <li><p>Maximizes total sales impact</p></li>
                                                <li><p>Harnesses the full potential of retail media investments</p></li>
                                                <li><p>Captures e-commerce and brick-and-mortar sales uplift</p></li>
                                            </ul>
                                            <p className='mb-0'>Bridge the online-offline divide and optimize your retail media strategy</p>
                                        </div>
                                    </div>
                                    <div className="col-12 col-lg-5 text-lg-end image-div">
                                        <img src={require('../../../assets/images/shopper-roi/11.jpg')} alt="Retail Media Halo Effect - Mapping Amazon/RMN ROI on Offline Sales" className='frames-image' />
                                    </div>
                                </div>
                            </div>
                            <div className="banner-section">
                                <div className="row g-lg-5 align-items-center">
                                    <div className="col-12 col-lg-5 image-div">
                                        <img src={require('../../../assets/images/shopper-roi/12.jpg')} alt="Predictive Modeling and Scenario Planning" className='frames-image' />
                                    </div>
                                    <div className="col-12 col-lg-7 content-div">
                                        <h6 className='sub-heading'>4. Predictive Modeling and Scenario Planning</h6>
                                        <div className="sub-content-div">
                                            <p>Our cutting-edge Causal AI powers this advanced tool for predictive modeling and scenario planning in the retail universe.</p>
                                            <ul className='list-style-dot'>
                                                <li><p>Simulate the impact of future marketing initiatives across all channels</p></li>
                                                <li><p>Optimize budget allocation and channel mix through our advanced algorithms</p></li>
                                                <li><p>Identify risks and opportunities based on historical data patterns and market trends</p></li>
                                                <li><p>Conduct "what-if" analyses to forecast outcomes of various strategies</p></li>
                                                <li><p>Gain actionable insights for maximizing ROI and expanding market share</p></li>
                                            </ul>
                                            <p className='mb-0'>With The AI Cockpit’s Connected Intelligence, chart your course through the complex retail universe with unprecedented clarity and foresight.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ShopperRoiPages
