import React from 'react'
import "../../../css/bootstrap.scss"
import "../../../css/component.scss"
import "../../../css/faq-accordion.scss"
import Tabs from '../../tabs/Tabs';

function Datapoem({ activeButton, scrollToDiv }) {
    
    return (
        <div className='bootstrap-content'>
            <div className="row justify-content-center gx-0">
                <div className="col-12 col-lg-10 container-div">
                    <div className="layer-container">
                        <Tabs activeButton={activeButton} scrollToDiv={scrollToDiv} />
                        <div className='tab-content-div'>
                            <div className="banner-section">
                                <div className="row g-lg-5 align-items-center">
                                    <div className="col-12 col-lg-7 content-div">
                                        <h1 className='heading'>This is how it works</h1>
                                        <h6 className='sub-heading'>1. Enhance MMM with Causal AI</h6>
                                        <div className="sub-content-div">
                                            <p>MMMs lay the foundation of your marketing galaxy : </p>
                                            <ul className='list-style'>
                                                <li>
                                                    <p>Identify the primary marketing levers</p>
                                                </li>
                                                <li>
                                                    <p>Quantifying initial channel effectiveness</p>
                                                </li>
                                                <li>
                                                    <p>Establish comparative benchmarks</p>
                                                </li>
                                            </ul>
                                            <p>Our Causal AI delivers:</p>
                                            <ul className='list-style-dot'>
                                                <li><p>Crystal-clear model interpretability</p></li>
                                                <li><p>Hyper-accurate predictive power</p></li>
                                                <li><p>Streamlined modeling efficiency</p></li>
                                            </ul>
                                            <p className='mb-0'>Fusing traditional methods with AI creates more robust, actionable marketing insights</p>
                                        </div>
                                    </div>
                                    <div className="col-12 col-lg-5 image-div text-lg-end">
                                        <img src={require('../../../assets/images/data-poem/1.jpg')} alt="Enhance MMM with Causal AI" className='frames-image' />
                                    </div>
                                </div>
                            </div>
                            <div className="banner-section">
                                <div className="row g-lg-5 align-items-center">
                                    <div className="col-12 col-lg-5 image-div">
                                        <img src={require('../../../assets/images/data-poem/2.jpg')} alt="Ensemble Modeling - The Power of Connection" className='frames-image' />
                                    </div>
                                    <div className="col-12 col-lg-7 content-div">
                                        <h6 className='sub-heading'>2. Ensemble Modeling - The Power of Connection</h6>
                                        <div className="sub-content-div">
                                            <p>
                                                Our hybrid approach integrates MMMs and deep learning to reveal:
                                            </p>
                                            <ul className='list-style'>
                                                <li>
                                                    <p>Cross-channel synergies and interactions</p>
                                                </li>
                                                <li>
                                                    <p>Unbiased, 360-degree views</p>
                                                </li>
                                                <li>
                                                    <p>Granular insights</p>
                                                </li>
                                                <li>
                                                    <p>Balanced interpretability and predictive power</p>
                                                </li>
                                            </ul>
                                            <p>Benefits of this connected approach:</p>
                                            <ul className='list-style-dot'>
                                                <li><p>Robust predictions from aggregated outputs</p></li>
                                                <li><p>Capture of linear and non-linear relationships</p></li>
                                                <li><p>Adaptability to market changes</p></li>
                                            </ul>
                                            <p>Enabling:</p>
                                            <ul className='list-style-dot'>
                                                <li><p>Comprehensive mix optimization</p></li>
                                                <li><p>Confident, data-driven decision-making</p></li>
                                                <li><p>Mastery over complex marketing challenges</p></li>
                                            </ul>
                                            <p className='mb-0'>This interconnected strategy provides a nuanced understanding of your marketing effectiveness, optimizing strategies and ROI across all campaigns and channels.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="banner-section">
                                <div className="row g-lg-5 align-items-center">
                                    <div className="col-12 col-lg-7 content-div">
                                        <h6 className='sub-heading'>3. Mapping Non-Linear and Complex Interactions</h6>
                                        <div className="sub-content-div">
                                            <p>While MMMs chart linear paths, our deep learning unveils non-linear patterns:</p>
                                            <ul className='list-style'>
                                                <li>
                                                    <p>Holistic marketing landscape view</p>
                                                </li>
                                                <li>
                                                    <p>Pinpointing of saturation events</p>
                                                </li>
                                                <li>
                                                    <p>Channel synergy discovery</p>
                                                </li>
                                                <li>
                                                    <p>Threshold effect identification</p>
                                                </li>
                                            </ul>
                                            <p>Our integrated approach:</p>
                                            <ul className='list-style-dot'>
                                                <li><p>Uncovers hidden data patterns</p></li>
                                                <li><p>Models intricate customer journeys</p></li>
                                                <li><p>Optimizes across all spend levels</p></li>
                                            </ul>
                                            <p>Empowering nuanced decisions:</p>
                                            <ul className='list-style-dot'>
                                                <li><p>Precision-engineered budget allocation</p></li>
                                                <li><p>Optimized campaign launch timing</p></li>
                                                <li><p>Enhanced cross-channel synergy optimization</p></li>
                                            </ul>
                                            <p className='mb-0'>Result: A more accurate, actionable map of your marketing universe, leading to optimized strategies and amplified ROI.</p>
                                        </div>
                                    </div>
                                    <div className="col-12 col-lg-5 image-div text-lg-end">
                                        <img src={require('../../../assets/images/data-poem/3.jpg')} alt="Mapping Non-Linear and Complex Interactions" className='frames-image' />
                                    </div>
                                </div>
                            </div>
                            <div className="banner-section">
                                <div className="row g-lg-5 align-items-center">
                                    <div className="col-12 col-lg-5 image-div">
                                        <img src={require('../../../assets/images/data-poem/4.jpg')} alt="Granularity" className='frames-image' />
                                    </div>
                                    <div className="col-12 col-lg-7 content-div">
                                        <h6 className='sub-heading'>4. Granularity</h6>
                                        <div className="sub-content-div">
                                            <p>Unlock hyper-targeted optimization, fine-tuning strategies at the most detailed level for maximum ROI:</p>
                                            <ul className='list-style'>
                                                <li>
                                                    <p>Precisely measure ROI for each marketing channel (e.g., TV, digital, print)</p>
                                                </li>
                                                <li>
                                                    <p>Evaluate performance of individual tactics within channels (e.g., display ads vs. search ads)</p>
                                                </li>
                                                <li>
                                                    <p>Assess impact of specific creative elements on campaign success</p>
                                                </li>
                                                <li>
                                                    <p>Measure short-term vs. long-term effects of marketing activities</p>
                                                </li>
                                                <li>
                                                    <p>Analyze ROI variations across different regions or markets</p>
                                                </li>
                                                <li>
                                                    <p>Understand how marketing effectiveness varies by customer group</p>
                                                </li>
                                                <li>
                                                    <p>Quantify interaction effects between different marketing efforts</p>
                                                </li>
                                            </ul>
                                            <p>With The AI Cockpit, navigate your marketing universe with unprecedented precision and insight.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Datapoem
