import React from 'react'
import "./screen50.scss"
import { useNavigate } from 'react-router-dom';
import { MdChevronRight } from 'react-icons/md';

function Screen50({activePage, setActivePage}) {
  const navigate = useNavigate()

  return (
    <div className={'full-screen screen50'} key="screen50">
      <div className="bg-screen"></div>
      <div className="mask"></div>
      {/* <div className="ellipse"></div> */}
      <div className="content bootstrap-content">
        <div className="row justify-content-end">
          <div className="col-12">
            <h1 className='heading-para'>
              Step into the future of Connected Intelligence <br />
              powered by Causal AI<br />
            </h1>
            <h2 className='heading-sub-para'>
              Transition via (Your MMM)<sup>DATA POEM</sup><br />
            </h2>
            <button className='dive-btn'
              onClick={() => {
                  navigate('/marketing-mix-modelling')
              }}
            ><span>Try Now</span> <MdChevronRight /></button>
          </div>
        </div>
      </div>
      <div className="ai-bottom-left">
        <img src={require('../../assets/images/ai-rocket.png')} alt="" />
      </div>
    </div>
  )
}

export default Screen50