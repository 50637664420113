import React from 'react'
import "../../../css/bootstrap.scss"
import "../../../css/component.scss"
import "../../../css/faq-accordion.scss"
import Tabs from '../../tabs/Tabs';

function LongTermRoi({ activeButton, scrollToDiv }) {
    
    return (
        <div className='bootstrap-content'>
            <div className="row justify-content-center gx-0">
                <div className="col-12 col-lg-10 container-div">
                    <div className="layer-container">
                        <Tabs activeButton={activeButton} scrollToDiv={scrollToDiv} />
                        <div className='tab-content-div'>
                            <div className="banner-section">
                                <div className="row g-lg-5 align-items-center">
                                    <div className="col-12 col-lg-7 content-div">
                                        <h1 className='heading'>This is how it works</h1>
                                        <h6 className='sub-heading'>1. Causal AI - Illuminating the Long-Term Impact of Marketing</h6>
                                        <div className="sub-content-div">
                                            <p className='mb-3'>Traditional models often fail to capture delayed effects and brand impact. Data POEM's advanced Causal AI, powered by deep learning, reveals true cause-and-effect relationships between marketing actions and long-term outcomes.</p>
                                            <p>Key features:</p>
                                            <ul className='list-style-dot'>
                                                <li>
                                                    <p>Isolates genuine causal contributions of each touchpoint</p>
                                                </li>
                                                <li>
                                                    <p>Accounts for market conditions and competitor actions</p>
                                                </li>
                                                <li>
                                                    <p>Uncovers hidden patterns and long-term value drivers</p>
                                                </li>
                                            </ul>
                                            <p>Benefits:</p>
                                            <ul className='list-style-dot'>
                                                <li><p>Reveals true long-term ROI across your marketing universe</p></li>
                                                <li><p>Empowers data-driven decisions</p></li>
                                                <li><p>Optimizes immediate results while ensuring sustained growth</p></li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-12 col-lg-5 text-lg-end image-div">
                                        <img src={require('../../../assets/images/long-term-roi/13.jpg')} alt="Causal AI - Illuminating the Long-Term Impact of Marketing" className='frames-image' />
                                    </div>
                                </div>
                            </div>
                            <div className="banner-section">
                                <div className="row g-lg-5 align-items-center">
                                    <div className="col-12 col-lg-5 image-div">
                                        <img src={require('../../../assets/images/long-term-roi/14.jpg')} alt="Total Long-Term Effect Measurement - Beyond Time Constraints" className='frames-image' />
                                    </div>
                                    <div className="col-12 col-lg-7 content-div">
                                        <h6 className='sub-heading'>2. Total Long-Term Effect Measurement - Beyond Time Constraints</h6>
                                        <div className="sub-content-div">
                                            <p>Data POEM's causal AI revolutionizes ROI:</p>
                                            <ul className='list-style-dot'>
                                                <li>
                                                    <p>Dynamically determines full marketing impact</p>
                                                </li>
                                                <li>
                                                    <p>Evaluates causal relationships until genuine decay is detected</p>
                                                </li>
                                                <li>
                                                    <p>Adapts to varying decay rates across activities and channels in your universe</p>
                                                </li>
                                                <li>
                                                    <p>Captures persistent, delayed, and compound effects </p>
                                                </li>
                                            </ul>
                                            <p>Benefits:</p>
                                            <ul className='list-style-dot'>
                                                <li><p>Reveals natural decay points, not arbitrary time limits</p></li>
                                                <li><p>Provides a comprehensive view of true ROI </p></li>
                                                <li><p>Prevents undervaluing strategies with extended impact</p></li>
                                                <li><p>Delivers accurate insights for strategic decisions and resource allocation</p></li>
                                            </ul>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="banner-section">
                                <div className="row g-lg-5 align-items-center">
                                    <div className="col-12 col-lg-7 content-div">
                                        <h6 className='sub-heading'>3. Decoding Long-Term Effects of Every Marketing Tactic</h6>
                                        <div className="sub-content-div">
                                            <p>The AI Cockpit excels in differentiating long-term effects across diverse marketing channels in your universe. It recognizes that a display banner's impact trajectory differs from a search ad or brand video.</p>
                                            <p>The AI:</p>
                                            <ul className='list-style-dot'>
                                                <li>
                                                    <p>Analyzes each channel's unique decay pattern</p>
                                                </li>
                                                <li>
                                                    <p>Quantifies immediate vs. delayed effects specific to each medium</p>
                                                </li>
                                                <li>
                                                    <p>Measures how channels interact and amplify each other</p>
                                                </li>
                                                <li>
                                                    <p>Accounts for channel-specific external factors </p>
                                                </li>
                                                <li>
                                                    <p>Adapts to changing consumer behaviors per channel over time</p>
                                                </li>
                                            </ul>
                                            <p className='mb-0'>This granular approach reveals the true long-term value of each channel, enabling marketers to optimize their mix for both short-term results and sustained brand impact</p>
                                        </div>
                                    </div>
                                    <div className="col-12 col-lg-5 text-lg-end image-div">
                                        <img src={require('../../../assets/images/long-term-roi/15.jpg')} alt="Decoding Long-Term Effects of Every Marketing Tactic" className='frames-image' />
                                    </div>
                                </div>
                            </div>
                            <div className="banner-section">
                                <div className="row g-lg-5 align-items-center">
                                    <div className="col-12 col-lg-5 image-div">
                                        <img src={require('../../../assets/images/long-term-roi/16.jpg')} alt="Measuring Total Effect of Sponsorships" className='frames-image' />
                                    </div>
                                    <div className="col-12 col-lg-7 content-div">
                                        <h6 className='sub-heading'>4. Measuring Total Effect of Sponsorships</h6>
                                        <div className="sub-content-div">
                                            <p>Understanding long-term ROI is crucial for strategic investments like sponsorships and events, which yield benefits beyond immediate sales in your marketing universe. Data POEM's long-term ROI solution captures the total value of these initiatives by:</p>
                                            <ul className='list-style-dot'>
                                                <li>
                                                    <p>Measuring extended impact timelines</p>
                                                </li>
                                                <li>
                                                    <p>Quantifying effects on brand perception and customer behavior</p>
                                                </li>
                                                <li>
                                                    <p>Analyzing cross-channel amplification</p>
                                                </li>
                                                <li>
                                                    <p>Accounting for cumulative benefits from repeated exposure</p>
                                                </li>
                                                <li>
                                                    <p>Isolating sponsorship impact from other activities in your marketing ecosystem</p>
                                                </li>
                                            </ul>
                                            <p className='mb-0'>Data POEM's Connected Intelligence reveals the full spectrum of sponsorship value, enabling informed decisions on high-stakes investments and optimal resource allocation for maximum long-term impact across your entire marketing universe.</p>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default LongTermRoi
