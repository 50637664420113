import React, { useEffect, useState } from 'react'
import "./screen3.scss"
// Add Meta Data To Page
import { Helmet } from 'react-helmet-async'

import Datapoem from '../../components/your-mmm-datapoem/datapoem-pages/Datapoem';
import GranularRoiPages from '../../components/granular-roi/granular-roi-pages/GranularRoiPages';
import ShopperRoiPages from '../../components/shopper-roi/shopper-roi-pages/ShopperRoiPages';
import AgileMonthlyRoi from '../../components/agile-monthly-roi/agile-monthly-roi/AgileMonthlyRoi';
import LongTermRoi from '../../components/long-term-roi/long-term-roi/LongTermRoi';
import InflightOptimization from '../../components/inflight-optimization/inflight-optimization/InflightOptimization';
import DataHub from '../../components/datahub/datahub/DataHub';
import CausalAi from '../../components/causal-ai/causal-ai/CausalAi';
import ExplainableAi from '../../components/explainable-ai/explainable-ai/ExplainableAi';
import NonlinearOptimization from '../../components/nonlinear-optimization/nonlinear-optimization/NonlinearOptimization';

function Screen3({ activePage, setActivePage, scrollToDiv }) {
  const [activeButton, setActiveButton] = useState('');

  useEffect(() => {
      if(window.location.pathname === '/granular-roi'){
        setActiveButton('granular');
      }
      if(window.location.pathname === '/marketing-mix-modelling'){
        setActiveButton('dataPoem');
      }
      if(window.location.pathname === '/shopper-roi'){
        setActiveButton('shopper');
      }
      if(window.location.pathname === '/agile-monthly-roi'){
        setActiveButton('agile');
      }
      if(window.location.pathname === '/long-term-roi'){
        setActiveButton('longTerm');
      }
      if(window.location.pathname === '/in-flight-optimization'){
        setActiveButton('inFlight');
      }
      if(window.location.pathname === '/causal-ai'){
        setActiveButton('causalAi');
      }
      if(window.location.pathname === '/explainable-ai'){
        setActiveButton('explainableAi');
      }
      if(window.location.pathname === '/non-linear-optimization'){
        setActiveButton('nonlinearOptimization');
      }
      if(window.location.pathname === '/the-data-hub'){
        setActiveButton('dataHub');
      }
    
  }, [window.location.pathname])

  return (<>
    {activeButton === 'granular' && <Helmet>
      <title>Data Poem - Granular ROI</title>
      <meta name='description' content="In the vast expanse of the marketing universe, investments are made at a highly granular level, targeting specific audience clusters and platform constellations. Yet, ROI is often measured at broad media and channel level, resulting in underreported ROI." />
    </Helmet>}
    <div className={'full-screen screen3'} key="screen3">
      <div className="content">
        {activeButton === 'granular' && <GranularRoiPages activeButton={activeButton} setActiveButton={setActiveButton} setActivePage={setActivePage} scrollToDiv={scrollToDiv} />}
        {activeButton === 'dataPoem' && <Datapoem activeButton={activeButton} setActiveButton={setActiveButton} setActivePage={setActivePage} scrollToDiv={scrollToDiv} />}
        {activeButton === 'shopper' && <ShopperRoiPages activeButton={activeButton} setActiveButton={setActiveButton} setActivePage={setActivePage} scrollToDiv={scrollToDiv} />}
        {activeButton === 'agile' && <AgileMonthlyRoi activeButton={activeButton} setActiveButton={setActiveButton} setActivePage={setActivePage} scrollToDiv={scrollToDiv} />}
        {activeButton === 'longTerm' && <LongTermRoi activeButton={activeButton} setActiveButton={setActiveButton} setActivePage={setActivePage} scrollToDiv={scrollToDiv} />}
        {activeButton === 'inFlight' && <InflightOptimization activeButton={activeButton} setActiveButton={setActiveButton} setActivePage={setActivePage} scrollToDiv={scrollToDiv} />}
        {activeButton === 'dataHub' && <DataHub activeButton={activeButton} setActiveButton={setActiveButton} setActivePage={setActivePage} scrollToDiv={scrollToDiv} />}
        {activeButton === 'causalAi' && <CausalAi activeButton={activeButton} setActiveButton={setActiveButton} setActivePage={setActivePage} scrollToDiv={scrollToDiv} />}
        {activeButton === 'explainableAi' && <ExplainableAi activeButton={activeButton} setActiveButton={setActiveButton} setActivePage={setActivePage} scrollToDiv={scrollToDiv} />}
        {activeButton === 'nonlinearOptimization' && <NonlinearOptimization activeButton={activeButton} setActiveButton={setActiveButton} setActivePage={setActivePage} scrollToDiv={scrollToDiv} />}
      </div>
      <div className="bottom-right"></div>
    </div>
  </>
  )
}

export default Screen3