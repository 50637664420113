import React from 'react'
import "../../../css/bootstrap.scss"
import "../../../css/component.scss"
import "../../../css/faq-accordion.scss"
import Tabs from '../../tabs/Tabs'

function DataHub({ activeButton, scrollToDiv }) {
    
    return (
        <div className='bootstrap-content'>
            <div className="row justify-content-center gx-0">
                <div className="col-12 col-lg-10 container-div">
                    <div className="layer-container">
                        <Tabs activeButton={activeButton} scrollToDiv={scrollToDiv} />
                        <div className="tab-content-div">
                            {<div className="banner-section">
                                <div className="row g-lg-5 align-items-center">
                                    {/* <div className="col-12 col-lg-5 text-lg-end image-div">
                                        <img src={require('../../../assets/images/long-term-roi/14.jpg')} alt="" className='frames-image' />
                                    </div> */}
                                    <div className="col-12 col-lg-12 content-div">
                                        <h1 className='heading'>This is how it works</h1>
                                        <h6 className='sub-heading'>1. Collection</h6>
                                        <div className="sub-content-div">
                                            <p>We collect data from hundreds of data sources across the marketing multiverse:</p>
                                            <ul className='row list-style-dot gx-0 mt-3'>
                                                <li className="col-4">
                                                    <p>Websites</p>
                                                </li>
                                                <li className="col-4">
                                                    <p>FEP</p>
                                                </li>
                                                <li className="col-4">
                                                    <p>Affiliate Programs</p>
                                                </li>
                                                <li className="col-4">
                                                    <p>TV</p>
                                                </li>
                                                <li className="col-4">
                                                    <p>Print</p>
                                                </li>
                                                <li className="col-4">
                                                    <p>Out-of-home</p>
                                                </li>
                                                <li className="col-4">
                                                    <p>Coupons</p>
                                                </li>
                                                <li className="col-4">
                                                    <p>Ibotta</p>
                                                </li>
                                                <li className="col-4">
                                                    <p>Fetch</p>
                                                </li>
                                                <li className="col-4">
                                                    <p>Amazon</p>
                                                </li>
                                                <li className="col-4">
                                                    <p>Target</p>
                                                </li>
                                                <li className="col-4">
                                                    <p>Walmart</p>
                                                </li>
                                                <li className="col-4">
                                                    <p>Kroger</p>
                                                </li>
                                                <li className="col-4">
                                                    <p>Instacart</p>
                                                </li>
                                                <li className="col-4">
                                                    <p>Gopuff</p>
                                                </li>
                                                <li className="col-4">
                                                    <p>Doordash</p>
                                                </li>
                                                <li className="col-4">
                                                    <p>Influencer</p>
                                                </li>
                                                <li className="col-4">
                                                    <p>PR</p>
                                                </li>
                                                <li className="col-4">
                                                    <p>Amazon Ads</p>
                                                </li>
                                                <li className="col-4">
                                                    <p>Bing Ads</p>
                                                </li>
                                                <li className="col-4">
                                                    <p>Google Ads</p>
                                                </li>
                                                <li className="col-4">
                                                    <p>Facebook</p>
                                                </li>
                                                <li className="col-4">
                                                    <p>Instagram</p>
                                                </li>
                                                <li className="col-4">
                                                    <p>Twitter</p>
                                                </li>
                                                <li className="col-4">
                                                    <p>TikTok</p>
                                                </li>
                                                <li className="col-4">
                                                    <p>Amazon DSP</p>
                                                </li>
                                                <li className="col-4">
                                                    <p>StackAdapt</p>
                                                </li>
                                                <li className="col-4">
                                                    <p>Offline Sales</p>
                                                </li>
                                                <li className="col-4">
                                                    <p>Online Sales</p>
                                                </li>
                                                <li className="col-4">
                                                    <p>Holidays</p>
                                                </li>
                                                <li className="col-4">
                                                    <p>TDP</p>
                                                </li>
                                                <li className="col-4">
                                                    <p>Temperature</p>
                                                </li>
                                                <li className="col-4">
                                                    <p>Super Bowl</p>
                                                </li>
                                                <li className="col-4">
                                                    <p>NBA</p>
                                                </li>
                                                <li className="col-4">
                                                    <p>Major League Baseball</p>
                                                </li>
                                            </ul>
                                            <p>Each source a unique star in your data galaxy, waiting to be explored and understood.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>}
                            {<div className="banner-section">
                                <div className="row g-lg-5 align-items-center">
                                    <div className="col-12 col-lg-5 image-div">
                                        <img src={require('../../../assets/images/datahub/2.jpg')} alt="Multi-Tiered Data Lake" className='frames-image' />
                                    </div>
                                    <div className="col-12 col-lg-7 content-div">
                                        <h6 className='sub-heading'>2. Multi-Tiered Data Lake</h6>
                                        <div className="sub-content-div">
                                            <p>Our system features:</p>
                                            <ul className='list-style-dot'>
                                                <li>
                                                    <p>Organized layers: Raw, Cleaned, and Curated data</p>
                                                </li>
                                                <li>
                                                    <p>Comprehensive metadata management capturing:</p>
                                                    <ul className='list-style-hyphen'>
                                                        <li>
                                                            <p>Campaign structure/hierarchy </p>
                                                        </li>
                                                        <li>
                                                            <p>Channel/medium taxonomies</p>
                                                        </li>
                                                        <li>
                                                            <p>Audience segment definitions</p>
                                                        </li>
                                                        <li>
                                                            <p>Creative assets metadata s</p>
                                                        </li>
                                                    </ul>
                                                </li>
                                            </ul>
                                            <p>Key capabilities:</p>
                                            <ul className='list-style-dot'>
                                                <li>
                                                    <p>Automated data discovery and cataloging</p>
                                                </li>
                                                <li>
                                                    <p>Up-to-date inventory of marketing data assets</p>
                                                </li>
                                                <li>
                                                    <p>Delta lake technologies for:</p>
                                                    <ul className='list-style-hyphen'>
                                                        <li>
                                                            <p>Versioning</p>
                                                        </li>
                                                        <li>
                                                            <p>Time travel capabilities, navigating through your data timeline</p>
                                                        </li>
                                                    </ul>
                                                </li>
                                            </ul>
                                            <p className='mb-0'>Benefit: Efficient, organized, and accessible marketing data for enhanced insights and decision-making</p>
                                        </div>
                                    </div>
                                </div>
                            </div>}
                            {<div className="banner-section">
                                <div className="row g-lg-5 align-items-center">
                                    <div className="col-12 col-lg-7 content-div">
                                        <h6 className='sub-heading'>3. Harmonization - Unifying Marketing Data</h6>
                                        <div className="sub-content-div">
                                            <p>Key features:</p>
                                            <ul className='list-style-dot'>
                                                <li>
                                                    <p>ML-driven automated schema inference and mapping across channels</p>
                                                </li>
                                                <li>
                                                    <p>Robust data quality framework enforcing marketing-specific rules</p>
                                                </li>
                                                <li>
                                                    <p>Custom data cleansing and standardization pipelines for:</p>
                                                    <ul className='list-style-hyphen'>
                                                        <li>
                                                            <p>Normalizing campaign names</p>
                                                        </li>
                                                        <li>
                                                            <p>Channel classifications</p>
                                                        </li>
                                                        <li>
                                                            <p>Geographic and demographic data</p>
                                                        </li>

                                                    </ul>
                                                </li>
                                                <li>
                                                    <p>Semantic layer defining consistent marketing metrics</p>
                                                </li>
                                            </ul>
                                            <p>Benefits:</p>
                                            <ul className='list-style-dot'>
                                                <li>
                                                    <p>Unified view across disparate platforms</p>
                                                </li>
                                                <li>
                                                    <p>Enforced data consistency</p>
                                                </li>
                                                <li>
                                                    <p>Standardized marketing terminology</p>
                                                </li>
                                            </ul>
                                            <p className='mb-0'>Result: Cleaner, more consistent data for accurate analysis and decision-making.</p>
                                        </div>
                                    </div>
                                    <div className="col-12 col-lg-5 text-lg-end image-div">
                                        <img src={require('../../../assets/images/datahub/3.jpg')} alt="Harmonization - Unifying Marketing Data" className='frames-image' />
                                    </div>
                                </div>
                            </div>}
                            {<div className="banner-section">
                                <div className="row g-lg-5 align-items-center">
                                    <div className="col-12 col-lg-5 text-lg-start image-div">
                                        <img src={require('../../../assets/images/datahub/4.jpg')} alt="Unification - The Cosmic Web of Data Integration" className='frames-image' />
                                    </div>
                                    <div className="col-12 col-lg-7 content-div">
                                        <h6 className='sub-heading'>4. Unification - The Cosmic Web of Data Integration</h6>
                                        <div className="sub-content-div">
                                            <p>Our platform seamlessly integrates diverse data sources, weaving the fabric of your marketing universe:</p>
                                            <ul className='list-style-dot'>
                                                <li>
                                                    <p>Paid, owned, and earned media</p>
                                                </li>
                                                <li>
                                                    <p>Trade, shopper, and retail data</p>
                                                </li>
                                                <li>
                                                    <p>Sales and external factors</p>
                                                </li>
                                            </ul>
                                            <p>Key advantages:</p>
                                            <ul className='list-style-dot'>
                                                <li>
                                                    <p>Connected Intelligence: Holistic cross-dimensional insights</p>
                                                </li>
                                                <li>
                                                    <p>Agile Decision-Making: Swift responses to market changes</p>
                                                </li>
                                                <li>
                                                    <p>Optimization: Fine-tuned cross-channel strategies</p>
                                                </li>
                                                <li>
                                                    <p>Coherent Data Foundation: Unified, reliable source</p>
                                                </li>
                                            </ul>
                                            <p>Benefit: Empowers businesses with agile, data-driven decision-making, driving growth and competitiveness in the dynamic, ever-expanding marketing universe.</p>
                                            <p className='mb-0'>With Data POEM's Connected Intelligence, navigate the vast expanse of information with unprecedented clarity and insight. </p>
                                        </div>
                                    </div>
                                </div>
                            </div>}
                            {/* {<div className="faq-section">
                                <h6 className='heading'>FAQs</h6>
                                <div className="col-12 content-div">
                                    <FaqAccordion />
                                </div>
                            </div>} */}
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default DataHub
