import React from 'react'
import "../../../css/bootstrap.scss"
import "../../../css/component.scss"
import "../../../css/faq-accordion.scss"
import FaqAccordion from '../faq-accordion/FaqAccordion'
import Tabs from '../../tabs/Tabs'

function FaqComponent({ scrollToDiv }) {

    return (
        <div className='bootstrap-content'>
            <div className="row justify-content-center gx-0">
                <div className="col-12 col-lg-10 container-div">
                    <div className="layer-container">
                        <div className="tabs-container">
                            <Tabs activeButton={'faqs'} scrollToDiv={scrollToDiv} />
                        </div>
                        <div className='tab-content-div'>
                            <div className="faq-section">
                                <div className="col-12">
                                    <FaqAccordion />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FaqComponent
