import React, { useEffect, useState } from 'react'
import "./screen1.scss"
// import backgroundVideo from "../../assets/home-video-bg.mp4"

const rotateArr = ['QSR', 'Hair Care', 'Nutrition', 'Snacks', 'Auto', 'Food', 'D2C'
    , 'BFSI'
]

function Screen1({ activePage, setActivePage }) {
    const [activeRotateIndex, setActiveRotateIndex] = useState(0);
    // const videoRef = useRef(null);
    // const [playRate, setPlayRate] = useState(1);
    // const [isLoaded, setIsLoaded] = useState(false)

    // useEffect(() => {
    //     if (videoRef.current) {
    //         videoRef.current.playbackRate = playRate;
    //     }
    // }, [playRate]);

    useEffect(() => {
        const interval = setInterval(() => {
            setActiveRotateIndex((prevIndex) => (prevIndex === rotateArr.length - 1 ? 0 : prevIndex + 1));
        }, 2000);

        return () => {
            clearInterval(interval)
        };
    }, [activePage]);

    return (
        <div className={'full-screen screen1'} key="screen1">
            {/* {!isLoaded &&  */}
            <div className="bg-screen"></div>
            {/* } */}
            {/* <video
                className={isLoaded ? "background-video" : "d-none"}
                ref={videoRef}
                src={backgroundVideo}
                onPlay={() => { setPlayRate(0.5) }}
                onLoadedData={() => { setIsLoaded(true) }}
                autoPlay loop muted>
            </video> */}
            <div className="ellipse"></div>
            <div className="content">
                <h3 className="text">The Next Frontier of Marketing Intelligence: <br /> Connected Intelligence</h3>
                <h3 className="text">We all knew it and wanted it but were hindered by <br /> the outdated, siloed measurements</h3>
                <h3 className="text"><span>DATA POEM</span> powered by causal AI is shattering <br /> the limits of Siloed data and Measurement</h3>
                <h3 className="text"><span>$2.2 billion</span> in ad spend analyzed monthly</h3>
                <h3 className="text"><span>30+</span> leading brands across <span key={activeRotateIndex} className='enter'>{rotateArr[activeRotateIndex]}</span> trust us.
                    {/* <div className="w-200">
                        <div className="rotating-container">
                            {rotateArr.map((text, index) => (
                                <div
                                    key={index}
                                    className={`rotating-text ${index === activeRotateIndex ? 'enter' : 'active'} ${index === (activeRotateIndex === 0 ? rotateArr.length - 1 : activeRotateIndex - 1) ? 'exit' : 'hide'}`}
                                >
                                    {text}
                                </div>
                            ))}
                        </div>
                    </div> */}
                    
                </h3>
                <h3 className='text'>Let's be the pioneers of this new era of marketing intelligence.</h3>
            </div>
            <div className="bottom-center">Scroll</div>
        </div>
    )
}

export default Screen1