import React from 'react'
import "./privacypolicy.scss"
import "../../css/bootstrap.scss"
import "../../css/component.scss"
import "../../css/faq-accordion.scss"
import PrivacyComponent from '../../components/privacy-component/PrivacyComponent'


function PrivacyStatement({ activePage, setActivePage, scrollToDiv }) {

    return (
        <div className={'full-screen faqscreen'} key="faqscreen">
            <div className="content">
                <PrivacyComponent />
            </div>
            <div className="bottom-right"></div>
        </div>
    )
}

export default PrivacyStatement