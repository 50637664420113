import React from 'react'
import "../../../css/bootstrap.scss"
import "../../../css/component.scss"
import "../../../css/faq-accordion.scss"
import Tabs from '../../tabs/Tabs';

function AgileMonthlyRoi({ activeButton, scrollToDiv }) {

    return (
        <div className='bootstrap-content'>
            <div className="row justify-content-center gx-0">
                <div className="col-12 col-lg-10 container-div">
                    <div className="layer-container">
                        <Tabs activeButton={activeButton} scrollToDiv={scrollToDiv} />
                        <div className='tab-content-div'>
                            <div className="banner-section">
                                <div className="row g-lg-5 align-items-center">
                                    <div className="col-12 col-lg-7 content-div">
                                        <h1 className='heading'>This is how it works</h1>
                                        <h6 className='sub-heading'>1. Monthly ROI with 2-Week Lag</h6>
                                        <div className="sub-content-div">
                                            <p>Our new paradigm offers:</p>
                                            <ul className='list-style-dot'>
                                                <li>
                                                    <p>Comprehensive view across all channels</p>
                                                </li>
                                                <li>
                                                    <p>Detailed audience segment insights</p>
                                                </li>
                                                <li>
                                                    <p>Consolidated broad and granular data</p>
                                                </li>

                                            </ul>
                                            <p>Benefits:</p>
                                            <ul className='list-style-dot'>
                                                <li><p>Reveals intricate patterns and audience-specific trends</p></li>
                                                <li><p>Enables precise optimization and personalized strategies</p></li>
                                                <li><p>Smarter budget allocation</p></li>
                                                <li><p>Improved targeting</p></li>
                                                <li><p>Higher overall ROI</p></li>
                                            </ul>
                                            <p className='mb-0'>This powerful approach empowers marketers to make swift, confident, data-driven decisions, piloting their business through the ever-expanding universe of opportunities with effective, audience-centric marketing strategies.</p>
                                        </div>
                                    </div>
                                    <div className="col-12 col-lg-5 image-div text-lg-end">
                                        <img src={require('../../../assets/images/agile-monthly-roi/17.jpg')} alt="Monthly ROI with 2-Week Lag" className='frames-image' />
                                    </div>
                                </div>
                            </div>
                            <div className="banner-section">
                                <div className="row g-lg-5 align-items-center">
                                    <div className="col-12 col-lg-5 image-div">
                                        <img src={require('../../../assets/images/agile-monthly-roi/18.jpg')} alt="Revolutionary Transfer Learning" className='frames-image' />
                                    </div>
                                    <div className="col-12 col-lg-7 content-div">
                                        <h6 className='sub-heading'>2. Revolutionary Transfer Learning</h6>
                                        <div className="sub-content-div">
                                            <p>To deliver monthly ROI models with just a 2-week lag, our engine harnesses transfer learning techniques. This allows for efficient model adaptation and fine-tuning using the most recent data.</p>
                                            <p>The pre-trained causal models, which capture the general causal relationships and dynamics, serve as the starting point for transfer learning.</p>
                                            <p className='mb-0'>As new data becomes available, our engine performs efficient fine-tuning of the causal models, updating the parameters and causal strengths. It's like recalibrating your marketing compass in real-time as you navigate through uncharted market territories.</p>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="banner-section">
                                <div className="row g-lg-5 align-items-center">
                                    <div className="col-12 col-lg-7 content-div">
                                        <h6 className='sub-heading'>3. Continuous Learning and Model Refinement </h6>
                                        <div className="sub-content-div">
                                            <p>As more data flows into our system over time, like stardust gathering to form new celestial bodies, our engine continuously refines and updates the causal models. This ensures they remain accurate and relevant in the face of evolving market dynamics, always ready to chart the newest constellations in your marketing sky.</p>
                                            <p className='mb-0'>Our incremental structure learning enables our engine to adapt the causal models in a streaming fashion, incorporating new data and adjusting the causal structures as needed.</p>
                                        </div>
                                    </div>
                                    <div className="col-12 col-lg-5 text-lg-end image-div">
                                        <img src={require('../../../assets/images/agile-monthly-roi/19.jpg')} alt="Continuous Learning and Model Refinement" className='frames-image' />
                                    </div>
                                </div>
                            </div>
                            <div className="banner-section">
                                <div className="row g-lg-5 align-items-center">
                                    <div className="col-12 col-lg-5 image-div">
                                        <img src={require('../../../assets/images/agile-monthly-roi/20.jpg')} alt="Counterfactual Inference and Scenario Simulation" className='frames-image' />
                                    </div>
                                    <div className="col-12 col-lg-7 content-div">
                                        <h6 className='sub-heading'>4. Counterfactual Inference and Scenario Simulation</h6>
                                        <div className="sub-content-div">
                                            <p>With counterfactual inference, you can evaluate the impact of hypothetical interventions or changes in marketing strategies.</p>
                                            <p>We simulate alternative scenarios by intervening on specific variables in the causal model and propagating the effects through the causal pathways.</p>
                                            <p className='mb-0'>By simulating multiple scenarios and comparing predicted outcomes, The AI Cockpit can recommend optimal marketing strategies.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AgileMonthlyRoi
