import React from 'react'
import "../../css/bootstrap.scss"
import "../../css/component.scss"
import "../../css/faq-accordion.scss"
import "./cookies-component.scss"
import { Link } from 'react-router-dom'

function CookiesComponent() {

    return (
        <div className='bootstrap-content cookies-page'>
            <div className="row justify-content-center gx-0">
                <div className="col-12 col-lg-10 container-div">
                    <div className="layer-container">
                        <div className='tab-content-div'>
                            <div className="faq-section">
                                <div className="col-12">
                                    <h4 className='cookies-heading'>Cookies Policy for DataPOEM</h4>
                                    <h6 className='effective-date'>Effective Date: 10-Sept-2024</h6>
                                    <h5 className='text'>At DataPOEM (the "Company," "we," "our," "us"), we use cookies to improve your experience on our website. This Cookies Policy explains what cookies are, how we use them, and how you can manage them.</h5>
                                    <h5 className='question'>What are Cookies?</h5>
                                    <p className='description'>Cookies are small text files placed on your device when you visit a website. They help the website function properly and enhance user experience by remembering your preferences and improving performance.</p>

                                    <h5 className='question'>Types of Cookies We Use:</h5>
                                    <p className='description'>Necessary Cookies: These cookies are essential for the website to function and cannot be switched off. They are usually set in response to actions such as logging in or filling out forms.
                                    </p>
                                    <p className='description'>Performance Cookies: These cookies allow us to count visits and traffic sources so we can measure and improve the performance of our website. All information collected by these cookies is aggregated and anonymous.
                                    </p>
                                    <p className='description'>Functional Cookies: These cookies enable the website to provide enhanced functionality and personalization. They may be set by us or by third-party providers.
                                    </p>
                                    <p className='description'>Targeting Cookies: These cookies may be set through our site by our advertising partners. They help us deliver relevant ads to you by tracking your browsing habits
                                    </p>

                                    <h5 className='question'>How We Use Cookies:</h5>
                                    <h5 className='question'>We use cookies to:</h5>
                                    <p className='description'>Analyze web traffic and user behavior to enhance website performance.</p>
                                    <p className='description'>Store your preferences and improve personalization.</p>
                                    <p className='description'>Assist with navigation and secure authentication.</p>

                                    <h5 className='question'>Managing Cookies:</h5>
                                    <p className='description'>You can control and manage cookies through your browser settings. Most browsers allow you to block or delete cookies, but doing so may affect the functionality of our website. For detailed guidance on managing cookies, refer to your browser's help section.</p>

                                    <h5 className='question'>Changes to this Cookies Policy:</h5>
                                    <p className='description'>We may update this Cookies Policy periodically. Any changes will be posted on this page, and we encourage you to review it regularly.</p>

                                    <p className='description'>If you have any questions about this Cookies Policy, please contact us at <Link to="mailto:admin@datapoem.com" className="navigation-text-color">[admin@datapoem.com]</Link> .</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CookiesComponent
