import { useEffect, useRef, useState } from "react";
import { BrowserRouter, Route, Routes, useNavigate } from "react-router-dom";
import Screen1 from "./screens/screen1/Screen1";
import Screen2 from "./screens/screen2/Screen2";
import Screen20 from "./screens/screen2/Screen20";
import Screen21 from "./screens/screen2/Screen21";
import Screen22 from "./screens/screen2/Screen22";
import Screen3 from "./screens/screen3/Screen3";
import Screen4 from "./screens/screen4/Screen4";
import Screen5 from "./screens/screen5/Screen5";
import Screen6 from "./screens/screen6/Screen6";
import Screen7 from "./screens/screen7/Screen7";
import Screen8 from "./screens/screen8/Screen8";
// import FullScreen from "./screens/screen8/fullscreen/FullScreen";
import FaqScreen from "./screens/faqscreen/FaqScreen";
import { isMobile } from 'react-device-detect';
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { IoArrowDown, IoArrowUp } from "react-icons/io5";
import { Helmet } from "react-helmet-async";
import Screen40 from "./screens/screen40/Screen40";
import Screen50 from "./screens/screen50/Screen50";
import CookiesPolicy from "./screens/cookies-policy/CookiesPolicy";
import PrivacyStatement from "./screens/privacy-statement/PrivacyStatement";
// Cookies
import { Link } from 'react-router-dom';
import { MdClose } from "react-icons/md";

export const Slider = ({activePage, totalScreens}) => {
  const sliderRef = useRef(null);
  const topPosition = ((activePage) / (totalScreens - 1)) * 100;

  return (<>
    <div className="sliderDiv" ref={sliderRef}>
      <div className="sliderDot" style={{top: `${topPosition}%`, width: '100%', height: '7%', borderRadius: '1rem'}}></div>
    </div>
  </>)
}

function App() {
  // Content
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [activePage, setActivePage] = useState(0)
  const [direction, setDirection] = useState(0); // 0: no direction, 1: down, -1: up
  const [scrolling, setScrolling] = useState(true); // 0: no direction, 1: down, -1: up
  const totalScreens = 12;
  const [canScroll, setCanScroll] = useState(true);
  const [warnVisible, setWarnVisible] = useState(true);
  const [growthScreen, setGrowthScreen] = useState(1);
  // Cookies
  const [showCookieInfo, setShowCookieInfo] = useState(true);
  const handleClose = () => {
    setShowCookieInfo(false);
  };

  const scrollToDiv = (activeIndex) => {
    setTimeout(() => {
      setActivePage(activeIndex)
    }, 300)
  }

  const DarkLogo = () => {
    const navigate = useNavigate()
    return (<>
      <div className="logo-center" onClick={() => {
        navigate('/');
        scrollToDiv(0);
        setIsSidebarOpen(false)  
      }}>
        <img src={require('./assets/images/DP-Logo.png')} className="pointer" alt="" />
      </div>
    </>)
  }

  const DarkHeader = () => {
    const navigate = useNavigate()
    return (<>
      <div className="contact-menu" onClick={() => {
        navigate('/lets-meet')
      }}>
        <img src={require('./assets/images/contact-us-white.png')} alt="" />
      </div>
      <div className="top-right" onClick={() => {setIsSidebarOpen(true)}}>
        <img src={require('./assets/images/menu.png')} alt="" />
      </div>
    </>)
  }

  const WhiteHeader = () => {
    const navigate = useNavigate()
    return (<>
      <div className="logo-center" onClick={() => {
        navigate('/')
        scrollToDiv(0)
        setIsSidebarOpen(false)
      }}>
        <img src={require('./assets/images/DP_AI_LOGO-White.png')} alt="" />
      </div>
      <div className="contact-menu" onClick={() => {
        navigate('/lets-meet')
      }}>
        <img src={require('./assets/images/contact-us-black.png')} alt="" />
      </div>
      <div className="top-right" onClick={() => {setIsSidebarOpen(true)}}>
        <img src={require('./assets/images/menu-dark.png')} alt="" />
      </div>
    </>)
  }

  const Sidebar = () => {
    const navigate = useNavigate();
    return (<>
      <div className={isSidebarOpen ? "bootstrap-content sidebar open" : 'sidebar close'}>
        <div className="text-end">
          <button onClick={() => {setIsSidebarOpen(false)}}>x</button>
        </div>
        <h3 onClick={() => {
          setIsSidebarOpen(false); 
          navigate('/')
          scrollToDiv(10);
        }}>Get To Know Us</h3>
        {/* <h3>Read Our Thoughts</h3> */}
        <h3 onClick={() => {
          setIsSidebarOpen(false);
          // localStorage.setItem('setPrev', activePage);
          navigate('/faqs');
        }}>FAQs</h3>
        <h3 onClick={() => {
          setIsSidebarOpen(false);
          navigate('/lets-meet')
        }}>Let's Meet</h3>
        <img src={require('./assets/images/sidebar-right.png')} alt="" />
      </div>
    </>)
  }

  const SliderArrows = () => {
    return (<>
      {activePage < 12 && <div className="right-center">
        <FaChevronUp className={`${activePage > 0 ? 'pointer' : ''}`} onClick={() => {
          if(activePage > 0){
            if(activePage === 8){
              setCanScroll(true)
            }
            if(activePage === 3){
              setGrowthScreen(1)
            }
            scrollToDiv(activePage-1);
          }
        }} color="#fff" />
        <Slider activePage={activePage} setActivePage={setActivePage} totalScreens={totalScreens} />
        <FaChevronDown className={`${activePage < 11 ? 'pointer' : ''}`} onClick={() => {
          if(activePage < 11){
            if(activePage === 8){
              setCanScroll(true)
            }
            if(activePage === 3 && growthScreen < 3){
              setGrowthScreen(growthScreen + 1)
            }else{
              scrollToDiv(activePage+1)
            }
          }
        }} color="#fff" />
      </div>}
      {warnVisible && canScroll && activePage < 12 && <div className="bottom-left">
        <p>Use keyboard arrows to navigate</p>
        <div>
          <IoArrowUp />
          <IoArrowDown />
        </div>
      </div>}
    </>)
  }

  const setActive = (direction) => {
    const newActivePage = Math.max(0, Math.min(activePage + direction, totalScreens - 1)); // Clamp activePage within bounds
    if(newActivePage === 4 && growthScreen < 3 && direction === 1){
      setGrowthScreen(growthScreen + 1);
      setDirection(direction);
    }
    // else if(newActivePage === 4 && growthScreen > 2 && direction === -1){
    //   setGrowthScreen(growthScreen - 1);
    //   setDirection(direction);
    // }
    else{
      setGrowthScreen(1)
      setActivePage(newActivePage);
      setDirection(direction);
    }
  };

  function throttle(func, delay) {
    let lastCall = 0;
    return function(...args) {
      const now = new Date().getTime();
      if (now - lastCall < delay) {
        return;
      }
      lastCall = now;
      return func(...args);
    };
  }
  
  useEffect(() => {
    const handleScroll = throttle((event) => {
      if (!scrolling || !canScroll || activePage >= 12) return;

      setScrolling(false);
      const newDirection = event.deltaY > 0 ? 1 : -1;
  
      // Check for valid scrolling and adjust activePage accordingly
      if (
        (newDirection === 1 && activePage < totalScreens - 1) ||
        (newDirection === -1 && activePage > 0)
      ) {
        setActive(newDirection);
      }

      setTimeout(() => {
        setScrolling(true);
      }, 1000); // Adjust timeout as needed
    }, 1000);
  
    window.addEventListener('wheel', handleScroll);
  
    return () => {
      window.removeEventListener('wheel', handleScroll);
    };
  }, [activePage, scrolling, canScroll, totalScreens]); // Add necessary dependencies

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (!canScroll || activePage >= 12) return;
      if (event.key === 'ArrowDown' && activePage < 11) {
        setDirection(1)
        if(warnVisible === true){
          setWarnVisible(false)
        }
        if(activePage === 3 && growthScreen < 3){
          setGrowthScreen(growthScreen + 1);
        }else{
          setGrowthScreen(1)
          setActivePage((prevPage) => prevPage + 1);
        }
      } else if (event.key === 'ArrowUp' && activePage > 0) {
        setDirection(-1)
        if(warnVisible === true){
          setWarnVisible(false)
        }
        if(activePage === 3 && growthScreen > 1){
          setGrowthScreen(growthScreen - 1);
        }else{
          setGrowthScreen(1)
          setActivePage((prevPage) => prevPage - 1);
        }
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [canScroll, activePage, totalScreens, growthScreen]);

  
  useEffect(() => {
    if(isMobile){
      window.location.href = 'https://m.datapoem.com';
    }
  }, [])

  return (<>
    {!isMobile && <div className="app">
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<>
          <Helmet>
            <title>Data Poem</title>
            <meta name='description' content="" />
          </Helmet>
          <Sidebar />
          <DarkHeader />
          <div className="screens" onClick={() => {setIsSidebarOpen(false)}}>
            <div className={`section ${activePage === 0 ? 'active fade-in' :  (activePage === 1 && direction === 1) ? 'fade-out' : 'd-none'}`}>
              <DarkLogo />
              <Screen1 activePage={activePage} setActivePage={setActivePage} />
            </div>
            <div className={`section ${(activePage === 1 && direction === 1) ? 'active animated fade-in': ''} ${(activePage === 1 && direction === -1) ? 'active fade-in': ''} ${(activePage === 2 && direction === 1) ? 'fade-out' : activePage !== 1 ? 'd-none' : ''}`}>
              <DarkLogo />
              <Screen2 activePage={activePage} setActivePage={setActivePage} />
            </div>
            <div className={`section ${activePage === 2 ? 'active fade-in' : 'd-none'}`}>
              <DarkLogo />
              <Screen20 activePage={activePage} setActivePage={setActivePage} />
            </div>
            <div className={`section ${activePage === 3 ? 'active fade-in' : 'd-none'}`}>
              {/* <DarkLogo /> */}
              <Screen21 activePage={activePage} setActivePage={setActivePage} growthScreen={growthScreen} setGrowthScreen={setGrowthScreen} />
            </div>
            <div className={`section ${activePage === 4 ? 'active fade-in' : 'd-none'}`}>
              <DarkLogo />
              <Screen22 activePage={activePage} setActivePage={setActivePage} />
            </div>
            <div className={`section ${(activePage === 5 && direction === 1) ? 'active fade-in' : (activePage === 5 && direction === -1) ? 'active backward2' : (activePage === 4 && direction === -1) ? 'fade-out2 backward' : (activePage === 6 && direction === 1) ? 'fade-out2 forward' : 'd-none'}`}>
              <DarkLogo />
              <Screen4 activePage={activePage} setActivePage={setActivePage} />
            </div>
            <div className={`section ${(activePage === 6 && direction === 1) ? 'active fade-in' : (activePage === 6 && direction === -1) ? 'active backward2' : (activePage === 5 && direction === -1) ? 'fade-out2 backward' : (activePage === 7 && direction === 1) ? 'fade-out2 forward' : 'd-none'}`}>
              <DarkLogo />
              <Screen40 activePage={activePage} setActivePage={setActivePage} />
            </div>
            <div className={`section ${activePage === 7 ? 'active fade-in' : (activePage === 6 && direction === -1) ? 'fade-out2 backward' : (activePage === 8 && direction === 1) ? 'fade-out2 forward': 'd-none'}`}>
              <DarkLogo />
              <Screen5 activePage={activePage} setActivePage={setActivePage} />
            </div>
            <div className={`section ${activePage === 8 ? 'active fade-in' : (activePage === 7 && direction === -1) ? 'fade-out2'  : 'd-none'}`}>
              <DarkLogo />
              <Screen50 activePage={activePage} setActivePage={setActivePage} />
            </div>
            <div className={`section ${activePage === 9 ? 'active fade-in' : 'd-none'}`}>
              <DarkLogo />
              <Screen8 key={'activePage-'+activePage} activePage={activePage} setActivePage={setActivePage} setCanScroll={setCanScroll} />
            </div>
            <div className={`section ${activePage === 10 ? 'active fade-in' : 'd-none'}`}>
              <DarkLogo />
              <Screen6 activePage={activePage} setActivePage={setActivePage} />
            </div>
            <div className={`section ${activePage === 11 ? 'active fade-in' : 'd-none'}`}>
              <DarkLogo />
              <Screen7 activePage={activePage} setActivePage={setActivePage} scrollToDiv={scrollToDiv} />
            </div>
          </div>
          <SliderArrows />
          {showCookieInfo && activePage === 0 && <div className='cookies-info'>
            <h5 className='cookies-content'>Welcome to datapoem.com!
              In order to provide a more relevant experience for you, we use cookies to enable some website functionality. 
              Cookies help us see which articles most interest you; allow you to easily share articles on social media; 
              permit us to deliver content, jobs and ads tailored to your interests and locations; 
              and provide many other site benefits. For more information, please review our <Link to='/cookies-policy' target="_blank" className='navigation-text-color'>Cookies Policy </Link> and <Link to='/privacy-statement' target="_blank" className='navigation-text-color'>Privacy Statement</Link>
            </h5>
            <MdClose className="icon close-icon" onClick={handleClose} />
          </div>}
        </>
        } />
        <Route path="/lets-meet" element={<>
          <Sidebar />
          <DarkHeader />
          <div className={`section active fade-in`} onClick={() => {setIsSidebarOpen(false)}}>
              <DarkLogo />
              <Screen7 activePage={activePage} setActivePage={setActivePage} scrollToDiv={scrollToDiv} />
            </div>
        </>} />
        <Route path="/faqs" element={<>
          <Sidebar />
          <WhiteHeader />
          <div className={`section active fade-in`} onClick={() => {setIsSidebarOpen(false)}}>
            <FaqScreen activePage={activePage} setActivePage={setActivePage} scrollToDiv={scrollToDiv} />
          </div>
        </>} />
        <Route path="/granular-roi" element={<>
          <Sidebar />
          <WhiteHeader />
          <div className={`section active fade-in`} onClick={() => {setIsSidebarOpen(false)}}>
            <Screen3 activePage={activePage} setActivePage={setActivePage} scrollToDiv={scrollToDiv} />
          </div>
        </>} />
        <Route path="/marketing-mix-modelling" element={<>
          <Sidebar />
          <WhiteHeader />
          <div className={`section active fade-in`} onClick={() => {setIsSidebarOpen(false)}}>
            <Screen3 activePage={activePage} setActivePage={setActivePage} scrollToDiv={scrollToDiv} />
          </div>
        </>} />
        <Route path="/shopper-roi" element={<>
          <Sidebar />
          <WhiteHeader />
          <div className={`section active fade-in`} onClick={() => {setIsSidebarOpen(false)}}>
            <Screen3 activePage={activePage} setActivePage={setActivePage} scrollToDiv={scrollToDiv} />
          </div>
        </>} />
        <Route path="/agile-monthly-roi" element={<>
          <Sidebar />
          <WhiteHeader />
          <div className={`section active fade-in`} onClick={() => {setIsSidebarOpen(false)}}>
            <Screen3 activePage={activePage} setActivePage={setActivePage} scrollToDiv={scrollToDiv} />
          </div>
        </>} />
        <Route path="/long-term-roi" element={<>
          <Sidebar />
          <WhiteHeader />
          <div className={`section active fade-in`} onClick={() => {setIsSidebarOpen(false)}}>
            <Screen3 activePage={activePage} setActivePage={setActivePage} scrollToDiv={scrollToDiv} />
          </div>
        </>} />
        <Route path="/in-flight-optimization" element={<>
          <Sidebar />
          <WhiteHeader />
          <div className={`section active fade-in`} onClick={() => {setIsSidebarOpen(false)}}>
            <Screen3 activePage={activePage} setActivePage={setActivePage} scrollToDiv={scrollToDiv} />
          </div>
        </>} />
        <Route path="/causal-ai" element={<>
          <Sidebar />
          <WhiteHeader />
          <div className={`section active fade-in`} onClick={() => {setIsSidebarOpen(false)}}>
            <Screen3 activePage={activePage} setActivePage={setActivePage} scrollToDiv={scrollToDiv} />
          </div>
        </>} />
        <Route path="/explainable-ai" element={<>
          <Sidebar />
          <WhiteHeader />
          <div className={`section active fade-in`} onClick={() => {setIsSidebarOpen(false)}}>
            <Screen3 activePage={activePage} setActivePage={setActivePage} scrollToDiv={scrollToDiv} />
          </div>
        </>} />
        <Route path="/non-linear-optimization" element={<>
          <Sidebar />
          <WhiteHeader />
          <div className={`section active fade-in`} onClick={() => {setIsSidebarOpen(false)}}>
            <Screen3 activePage={activePage} setActivePage={setActivePage} scrollToDiv={scrollToDiv} />
          </div>
        </>} />
        <Route path="/the-data-hub" element={<>
          <Sidebar />
          <WhiteHeader />
          <div className={`section active fade-in`} onClick={() => {setIsSidebarOpen(false)}}>
            <Screen3 activePage={activePage} setActivePage={setActivePage} scrollToDiv={scrollToDiv} />
          </div>
        </>} />
        <Route path="/cookies-policy" element={<>
          <Sidebar />
          <WhiteHeader />
          <div className={`section active fade-in`} onClick={() => {setIsSidebarOpen(false)}}>
            <CookiesPolicy activePage={activePage} setActivePage={setActivePage} scrollToDiv={scrollToDiv} />
          </div>
        </>} />
        <Route path="/privacy-statement" element={<>
          <Sidebar />
          <WhiteHeader />
          <div className={`section active fade-in`} onClick={() => {setIsSidebarOpen(false)}}>
            <PrivacyStatement activePage={activePage} setActivePage={setActivePage} scrollToDiv={scrollToDiv} />
          </div>
        </>} />
        {/* {activePage === 11 && <div className={`section ${activePage === 11 ? 'active fade-in' : 'fade-out d-none'} ${direction === -1 ? 'previous' : direction === 1 ? 'next' : ''}`}>
            <FullScreen activePage={activePage} setActivePage={setActivePage} setCanScroll={setCanScroll} />
          </div>} */}
      </Routes>
    </BrowserRouter>
  </div>
  }
  </>);
}

export default App;
