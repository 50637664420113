import React, { useState } from 'react'
import "./mainscreen.scss"
import { MdKeyboardArrowDown, MdOutlineHome, MdOutlineSend } from 'react-icons/md'
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';

const MainScreen = ({ activePage, setActivePage, scrollToDiv }) => {
    const navigate = useNavigate();
    const [isOpen, setIsOpen] = useState(false);
    const [formInfo, setFormInfo] = useState({ name: '', phoneNumber: '', email: '' })

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
        // setActiveButton('dropdown');
    };

    const submit = async () => {
        let apiRes = await fetch('https://datapoem.ai/api/user-module/prospective-user/', {
            method: 'POST',
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(formInfo)
        });
        let apiData = await apiRes.json();
        if (apiData.success === true) {
            Swal.fire({
                title: `Thanks for considering us.`,
                text: `Allow us a little time to get back to you.`,
                icon: 'success',
                showConfirmButton: false,
                heightAuto: false
            }).then(() => {
                setFormInfo({ name: '', phoneNumber: '', email: '' })
            })
        }
    }

    return (
        <div className='bootstrap-content'>
            <div className="row justify-content-center gx-0">
                <div className="col-12 col-lg-10 container-div">
                    <div className="lets-meet">
                        <div className="tabs">
                            <button
                                className={`btn btn-black home-btn text-white`}
                                onClick={() => {
                                    setIsOpen(false)
                                    navigate('/')
                                    scrollToDiv(4)
                                }}
                            >
                                <MdOutlineHome />
                            </button>
                            <button
                                className={`btn btn-black text-white`}
                                onClick={() => {
                                    setIsOpen(false)
                                    navigate('/granular-roi')
                                }}
                            >
                                Granular ROI
                            </button>
                            <button
                                className={`btn btn-black data-poem text-white`}
                                onClick={() => {
                                    setIsOpen(false)
                                    navigate('/marketing-mix-modelling')
                                }}
                            >
                                (Your MMM) <span className='text-right'>Data Poem</span>
                            </button>
                            <button
                                className={`btn btn-black text-white`}
                                onClick={() => {
                                    setIsOpen(false)
                                    navigate('/shopper-roi')
                                }}
                            >
                                Shopper ROI
                            </button>
                            <button
                                className={`btn btn-black text-white`}
                                onClick={() => {
                                    
                                    setIsOpen(false)
                                    navigate('/agile-monthly-roi')
                                }}
                            >
                                Agile Monthly ROI
                            </button>
                            <button
                                className={`btn btn-black text-white`}
                                onClick={() => {
                                    setIsOpen(false)
                                    navigate('/long-term-roi')
                                }}
                            >
                                Long-term ROI
                            </button>
                            <button
                                className={`btn btn-black text-white`}
                                onClick={() => {
                                    setIsOpen(false)
                                    navigate('/in-flight-optimization')
                                }}
                            >
                                In-flight Optimization
                            </button>
                            <div className="dropdown">
                                <button
                                    className={`btn btn-black ai-engine text-white`}
                                    type="button"
                                    onClick={toggleDropdown}
                                >
                                    The AI Engine
                                    <span
                                        className="arrow"
                                        style={{ transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)' }}
                                    >
                                        <MdKeyboardArrowDown />
                                    </span>
                                </button>
                                {isOpen && (
                                    <div className="dropdown-menu show">
                                        <button className={`dropdown-item`} onClick={() => {
                                            setIsOpen(false)
                                            navigate('/causal-ai')
                                        }}>Causal AI</button>
                                        <button className={`dropdown-item`} onClick={() => {
                                            setIsOpen(false)
                                            navigate('/explainable-ai')
                                        }}>Explainable AI</button>
                                        <button className={`dropdown-item`} onClick={() => {
                                            setIsOpen(false)
                                            navigate('/non-linear-optimization')
                                        }}>Non Linear Optimization</button>
                                    </div>
                                )}
                            </div>
                            <button
                                className={`btn btn-black text-white`}
                                onClick={() => {
                                    setIsOpen(false)
                                    navigate('/')
                                    scrollToDiv(7)
                                }}
                            >
                                The Data Hub
                            </button>
                        </div>
                        <div className='tab-content-div'>
                            <div className="banner-section">
                                <div className="row align-items-start g-0 w-100">
                                    <div className="col-12 content-div">
                                        <h3 className='heading'>
                                        The competitive edge gained by connecting with us, <br />
                                        far outweighs the limitations of disconnected analytics
                                        </h3>
                                        <div className="row inputBox g-0">
                                            <div className="col-12 col-lg-3">
                                                <input type="text"
                                                    placeholder='Name'
                                                    value={formInfo.name}
                                                    onChange={(ev) => {
                                                        setFormInfo({ ...formInfo, name: ev.target.value })
                                                    }}
                                                />
                                                <input type="text"
                                                    placeholder='Phone Number'
                                                    value={formInfo.phoneNumber}
                                                    onChange={(ev) => {
                                                        setFormInfo({ ...formInfo, phoneNumber: ev.target.value })
                                                    }}
                                                />
                                                <input type="text"
                                                    placeholder='Email'
                                                    value={formInfo.email}
                                                    onChange={(ev) => {
                                                        setFormInfo({ ...formInfo, email: ev.target.value })
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <button className='dive-btn' onClick={() => { submit() }}>Initiate Contact <MdOutlineSend className='ms-2 icon' /></button>
                                    </div>
                                </div>
                                <div className="mt-auto footer-text w-100">
                                    <p className='mb-0'>33 Wood Ave S Ste 600, Iselin, <br />
                                        NJ 08830, United States
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MainScreen
