import React from 'react'
import "./faqscreen.scss"
import FaqComponent from '../../components/faq/faqcomponent/FaqComponent';

function FaqScreen({ activePage, setActivePage, scrollToDiv }) {

  return (
    <div className={'full-screen faqscreen'} key="faqscreen">
      <div className="content">
        <FaqComponent setActivePage={setActivePage} scrollToDiv={scrollToDiv} />
      </div>
      <div className="bottom-right"></div>
    </div>
  )
}

export default FaqScreen