import React from 'react'
import "../../../css/bootstrap.scss"
import "../../../css/component.scss"
import "../../../css/faq-accordion.scss"
import Tabs from '../../tabs/Tabs';

function ExplainableAi({ activeButton, scrollToDiv }) {
    return (
        <div className='bootstrap-content'>
            <div className="row justify-content-center gx-0">
                <div className="col-12 col-lg-10 container-div">
                    <div className="layer-container">
                        <Tabs activeButton={activeButton} scrollToDiv={scrollToDiv} />
                        <div className="tab-content-div">
                            <div className="banner-section">
                                <div className="row g-lg-5 align-items-center">
                                    <div className="col-12 col-lg-7 content-div">
                                        <div className="row">
                                            <div className="col-12">
                                                <h1 className='heading'>Explainable AI - The How</h1>
                                                <p>
                                                    The inner workings of neural networks have been blackboxes, Our XAI peers through a keyhole into the network’s decision making process and unveils the secrets hidden within. With feature attribution, we find which ingredient has the strongest influence, say on a dish’s flavor :
                                                </p>
                                            </div>
                                        </div>
                                        <h6 className='sub-heading'>1. Deep Learning - Harnessing Neural Networks </h6>
                                        <div className="sub-content-div">
                                            <p>Deep Learning uses multi-layered neural networks to :</p>
                                            <ul className='list-style-dot'>
                                                <li>
                                                    <p>Learn hierarchical data representations</p>
                                                </li>
                                                <li>
                                                    <p>Extract abstract features from raw inputs</p>
                                                </li>
                                                <li>
                                                    <p>Identify complex patterns and relationships</p>
                                                </li>
                                            </ul>
                                            <p>Advantages:</p>
                                            <ul className='list-style-dot'>
                                                <li><p>Tackles intricate problems with high accuracy</p></li>
                                                <li><p>Excels in image/speech recognition, NLP, and complex decision-making </p></li>
                                                <li><p>Mimics the human brain's neural structure</p></li>
                                                <li><p>Effective with unstructured data</p></li>
                                            </ul>
                                            <p className='mb-0'>This approach enables sophisticated pattern recognition and generalization, uncovering insights that traditional algorithms might miss in the vast expanse of your marketing universe.</p>
                                        </div>
                                    </div>
                                    <div className="col-12 col-lg-5 image-div text-lg-end">
                                        <img src={require('../../../assets/images/explainable-ai/5.jpg')} alt="Deep Learning - Harnessing Neural Networks" className='frames-image' />
                                    </div>
                                </div>
                            </div>
                            <div className="banner-section">
                                <div className="row g-lg-5 align-items-center">
                                    <div className="col-12 col-lg-5 image-div">
                                        <img src={require('../../../assets/images/explainable-ai/6.jpg')} alt="Explainable AI - Illuminating the Black Holes of Deep Learning" className='frames-image' />
                                    </div>
                                    <div className="col-12 col-lg-7 content-div">
                                        <h6 className='sub-heading'>2. Explainable AI - Illuminating the Black Holes of Deep Learning</h6>
                                        <div className="sub-content-div">
                                            <p>XAI addresses the "black box" nature of deep learning models:</p>
                                            <ul className='list-style-dot'>
                                                <li>
                                                    <p>Reveals the importance of input features</p>
                                                </li>
                                                <li>
                                                    <p>Illuminating factors driving predictions</p>
                                                </li>
                                                <li>
                                                    <p>Interprets model behavior</p>
                                                </li>
                                            </ul>
                                            <p>Benefits:</p>
                                            <ul className='list-style-dot'>
                                                <li><p>Enhances trust in AI decisions, building bridges between human and machine intelligence</p></li>
                                                <li><p>Enables validation of model reasoning, ensuring your AI navigator stays on course</p></li>
                                                <li><p>Identifies potential biases, avoiding gravitational anomalies in your decision-making</p></li>
                                                <li><p>Improves model debugging, fine-tuning your AI spacecraft for optimal performance</p></li>
                                                <li><p>Supports regulatory compliance, adhering to the laws of the marketing cosmos</p></li>
                                            </ul>
                                            <p className='mb-0'>XAI bridges complex algorithms and human understanding, facilitating informed decision-making and responsible AI deployment across the marketing multiverse. It ensures your AI aligns with expectations and domain knowledge across all applications in your business galaxy.</p>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="banner-section">
                                <div className="row g-lg-5 align-items-center">
                                    <div className="col-12 col-lg-7 content-div">
                                        <h6 className='sub-heading'>3. Explainability Through Missingness Analysis</h6>
                                        <div className="sub-content-div">
                                            <p>Enhance AI explainability by examining feature missingness :</p>
                                            <ul className='list-style-dot'>
                                                <li>
                                                    <p>Remove/add features to observe impact on model decisions</p>
                                                </li>
                                                <li>
                                                    <p>Gauge importance of each feature</p>
                                                </li>
                                                <li>
                                                    <p>Reveal crucial information for predictions</p>
                                                </li>
                                            </ul>
                                            <p>Benefits:</p>
                                            <ul className='list-style-dot'>
                                                <li><p>Identifies critical features</p></li>
                                                <li><p>Assesses model robustness to incomplete data</p></li>
                                                <li><p>Uncovers potential biases/vulnerabilities</p></li>
                                                <li><p>Provides insights on input reliance and generalization</p></li>
                                            </ul>
                                            <p>This approach offers intuitive explanations for non-technical stakeholders, highlighting key decision factors and model adaptability. It supports transparent, trustworthy AI systems.</p>
                                            <p className='mb-0'>With Data POEM's Connected Intelligence, navigate the intricate neural networks of your AI decision-making process. Illuminate the black boxes, chart the unseen, and make informed decisions with unprecedented clarity and confidence.</p>
                                        </div>
                                    </div>
                                    <div className="col-12 col-lg-5 image-div text-lg-end">
                                        <img src={require('../../../assets/images/explainable-ai/7.jpg')} alt="Explainability Through Missingness Analysis" className='frames-image' />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ExplainableAi
