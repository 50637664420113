import React from 'react'
import "../../../css/bootstrap.scss"
import "../../../css/component.scss"
import "../../../css/faq-accordion.scss"
import Tabs from '../../tabs/Tabs';

function NonlinearOptimization({ activeButton, scrollToDiv }) {
    
    return (
        <div className='bootstrap-content'>
            <div className="row justify-content-center gx-0">
                <div className="col-12 col-lg-10 container-div">
                    <div className="layer-container">
                        <Tabs activeButton={activeButton} scrollToDiv={scrollToDiv} />
                        <div className="tab-content-div">
                            <div className="banner-section">
                                <div className="row g-lg-5 align-items-center">
                                    <div className="col-12 col-lg-7 content-div">
                                        <h1 className='heading'>Liberating ROI Optimization from Linear Constraints</h1>
                                        <p>Farewell, antiquated ROI navigational tools! The era of simplistic saturation curves is history. These outdated instruments, with their linear worldview, fail to capture the intricate, interconnected reality of modern marketing. Their limitations obscure hidden opportunities and synergies in the vast marketing cosmos.</p>
                                        <p>Welcome to the new frontier of AI-driven, multivariate optimization. Embrace Data POEM's revolutionary approach, unlocking the true potential of your marketing efforts in our dynamic, non-linear universe.</p>
                                        <h6 className='sub-heading'>1. Advanced Optimization through Neural Networks</h6>
                                        <div className="sub-content-div">
                                            <p>Our optimizer harnesses the power of neural networks to predict business KPIs, utilizing:</p>
                                            <ul className='list-style-dot'>
                                                <li>
                                                    <p>Gradient descent and non-linear metaheuristics</p>
                                                </li>
                                                <li>
                                                    <p>Complex feature interactions and non-linear relationships</p>
                                                </li>
                                            </ul>
                                            <p>Key features:</p>
                                            <ul className='list-style-dot'>
                                                <li><p>Navigates complex decision spaces with precision</p></li>
                                                <li><p>Combines efficient local search with broad exploration</p></li>
                                                <li><p>Avoids local optima traps</p></li>
                                                <li><p>Discovers robust, globally optimal solutions</p></li>
                                            </ul>
                                            <p>Benefits:</p>
                                            <ul className='list-style-dot'>
                                                <li><p>Translates deep insights into actionable strategies</p></li>
                                                <li><p>Maximizes KPIs while accounting for variable interdependencies</p></li>
                                            </ul>
                                            <p className='mb-0'>Achieve superior optimization through our advanced AI techniques</p>
                                        </div>
                                    </div>
                                    <div className="col-12 col-lg-5 image-div text-lg-end">
                                        <img src={require('../../../assets/images/nonlinear-optimization/8.jpg')} alt="Advanced Optimization through Neural Networks" className='frames-image' />
                                    </div>
                                </div>
                            </div>
                            <div className="banner-section">
                                <div className="row g-lg-5 align-items-center">
                                    <div className="col-12 col-lg-5 image-div">
                                        <img src={require('../../../assets/images/nonlinear-optimization/9.jpg')} alt="Multivariate Optimization - Holistic Media Strategy" className='frames-image' />
                                    </div>
                                    <div className="col-12 col-lg-7 content-div">
                                        <h6 className='sub-heading'>2. Multivariate Optimization - Holistic Media Strategy</h6>
                                        <div className="sub-content-div">
                                            <p>Our approach calculates Marginal ROIs across all media variables simultaneously, ensuring comprehensive optimization.</p>
                                            <p>Key features:</p>
                                            <ul className='list-style-dot'>
                                                <li>
                                                    <p>Captures complex interactions and synergies between marketing channels</p>
                                                </li>
                                                <li>
                                                    <p>Assesses each variable's true impact on the overall outcome</p>
                                                </li>
                                            </ul>
                                            <p>Benefits:</p>
                                            <ul className='list-style-dot'>
                                                <li><p>Identifies optimal resource allocation across all channels</p></li>
                                                <li><p>Detects cross-channel effects and potential cannibalization</p></li>
                                                <li><p>Maximizes overall ROI by balancing investments</p></li>
                                            </ul>
                                            <p className='mb-0'>This nuanced strategy leads to better-informed decisions and improved resource allocation across your entire media mix, avoiding siloed approaches and revealing the interconnected nature of your marketing efforts.</p>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="banner-section">
                                <div className="row g-lg-5 align-items-center">
                                    <div className="col-12 col-lg-7 content-div">
                                        <h6 className='sub-heading'>3. Non-Linear Optimization - Mapping Complex Media Impacts</h6>
                                        <div className="sub-content-div">
                                            <p>The AI Cockpit leverages non-linear media impacts on KPIs and interconnected variable influences to calculate marginal ROI</p>
                                            <p>Key features:</p>
                                            <ul className='list-style-dot'>
                                                <li>
                                                    <p>Captures realistic, complex interactions</p>
                                                </li>
                                                <li>
                                                    <p>Represents diminishing returns accurately</p>
                                                </li>
                                                <li>
                                                    <p>Identifies synergies and saturation points across channels</p>
                                                </li>
                                                <li>
                                                    <p>Optimizes across varying spend levels</p>
                                                </li>
                                            </ul>
                                            <p>Benefits:</p>
                                            <ul className='list-style-dot'>
                                                <li><p>Accounts for how changes in one variable affects others</p></li>
                                                <li><p>Provides a holistic view of media performance</p></li>
                                                <li><p>Enables nuanced, effective optimization strategies</p></li>
                                            </ul>

                                            <p className='mb-0'>Result: More accurate media mix optimization, driving better KPI outcomes across your entire marketing universe.</p>
                                        </div>
                                    </div>
                                    <div className="col-12 col-lg-5 image-div text-lg-end">
                                        <img src={require('../../../assets/images/nonlinear-optimization/10.jpg')} alt="Non-Linear Optimization - Mapping Complex Media Impacts" className='frames-image' />
                                    </div>
                                </div>
                            </div>
                            <div className="banner-section">
                                <div className="row g-lg-5 align-items-center">
                                    <div className="col-12 col-lg-5 image-div">
                                        <img src={require('../../../assets/images/nonlinear-optimization/11.jpg')} alt="Dynamic Platform - Adaptive Optimization" className='frames-image' />
                                    </div>
                                    <div className="col-12 col-lg-7 content-div">
                                        <h6 className='sub-heading'>4. Dynamic Platform - Adaptive Optimization</h6>
                                        <div className="sub-content-div">
                                            <p>Our Platform updates learnings monthly, ensuring optimization based on the latest data patterns in your ever-evolving marketing universe</p>
                                            <p>Key benefits:</p>
                                            <ul className='list-style-dot'>
                                                <li>
                                                    <p>Real-time responsiveness to market fluctuations</p>
                                                </li>
                                                <li>
                                                    <p>Continuous improvement of strategies</p>
                                                </li>
                                                <li>
                                                    <p>Adaptation to seasonal trends and emerging patterns</p>
                                                </li>
                                                <li>
                                                    <p>Mitigation of outdated assumptions and model drift</p>
                                                </li>
                                            </ul>
                                            <p>This dynamic approach enables:</p>
                                            <ul className='list-style-dot'>
                                                <li><p>Agile decision-making</p></li>
                                                <li><p>Staying ahead of market shifts</p></li>
                                                <li><p>More accurate predictions</p></li>
                                                <li><p>Optimized results across all dimensions of your marketing efforts</p></li>
                                            </ul>
                                            <p className='mb-0'>Regular updates create a virtuous cycle of learning and improvement, maintaining relevance in the rapidly changing business universe.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NonlinearOptimization
