import React, { useState } from 'react'
import { MdKeyboardArrowDown, MdOutlineHome } from 'react-icons/md';
import { useNavigate } from 'react-router-dom'

function Tabs({activeButton, scrollToDiv}) {
    const navigate = useNavigate();
    const [isOpen, setIsOpen] = useState(false);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

  return (
    <div className="tabs">
        <button
            className={`btn btn-black home-btn text-white`}
            onClick={() => {
                navigate('/')
                if(activeButton === 'dataHub'){
                    scrollToDiv(7)
                }else if((activeButton === 'causalAi' || activeButton === 'explainableAi')){
                    scrollToDiv(5)
                }else if(activeButton === 'nonlinearOptimization'){
                    scrollToDiv(6)
                }else if(activeButton === 'dataPoem'){
                    scrollToDiv(8)
                }else if(activeButton === 'faqs'){
                    scrollToDiv(0)
                }else{
                    scrollToDiv(4)
                }
            }}
        >
            <MdOutlineHome />
        </button>
        <button
            className={`btn btn-black text-white ${activeButton === 'granular' ? 'active' : ''}`}
            onClick={() => {
                setIsOpen(false);
                navigate('/granular-roi')
            }}
        >
            Granular ROI
        </button>
        <button
            className={`btn btn-black data-poem text-white ${activeButton === 'dataPoem' ? 'active' : ''}`}
            onClick={() => {
                setIsOpen(false);
                navigate('/marketing-mix-modelling')
            }}
        >
            (Your MMM) <span className='text-right'>Data Poem</span>
        </button>
        <button
            className={`btn btn-black text-white ${activeButton === 'shopper' ? 'active' : ''}`}
            onClick={() => {
                setIsOpen(false);
                navigate('/shopper-roi')
            }}
        >
            Shopper ROI
        </button>
        <button
            className={`btn btn-black text-white ${activeButton === 'agile' ? 'active' : ''}`}
            onClick={() => {
                setIsOpen(false);
                navigate('/agile-monthly-roi')
            }}
        >
            Agile Monthly ROI
        </button>
        <button
            className={`btn btn-black text-white ${activeButton === 'longTerm' ? 'active' : ''}`}
            onClick={() => {
                setIsOpen(false);
                navigate('/long-term-roi')
            }}
        >
            Long-term ROI
        </button>
        <button
            className={`btn btn-black text-white ${activeButton === 'inFlight' ? 'active' : ''}`}
            onClick={() => {
                setIsOpen(false);
                navigate('/in-flight-optimization')
            }}
        >
            In-flight Optimization
        </button>
        <div className="dropdown">
            <button
                className={`btn btn-black ai-engine text-white`}
                type="button"
                onClick={toggleDropdown}
            >
                The AI Engine
                <span
                    className="arrow"
                    style={{ transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)' }}
                >
                    <MdKeyboardArrowDown />
                </span>
            </button>
            {isOpen && (
                <div className="dropdown-menu show">
                    <button className={`dropdown-item ${activeButton === 'causalAi' ? 'active' : ''}`} onClick={() => {
                        setIsOpen(false);
                        navigate('/causal-ai')
                    }}>Causal AI</button>
                    <button className={`dropdown-item ${activeButton === 'explainableAi' ? 'active' : ''}`} onClick={() => {
                        setIsOpen(false);
                        navigate('/explainable-ai')
                    }}>Explainable AI</button>
                    <button className={`dropdown-item ${activeButton === 'nonlinearOptimization' ? 'active' : ''}`} onClick={() => {
                        setIsOpen(false);
                        navigate('/non-linear-optimization')
                    }}>Non Linear Optimization</button>
                </div>
            )}
        </div>
        <button
            className={`btn btn-black text-white ${activeButton === 'dataHub' ? 'active' : ''}`}
            onClick={() => {
                setIsOpen(false);
                navigate('/the-data-hub')
            }}
        >
            The Data Hub
        </button>
    </div>
  )
}

export default Tabs