import React from 'react'
import "../../../css/bootstrap.scss"
import "../../../css/component.scss"
import "../../../css/faq-accordion.scss"
import Tabs from '../../tabs/Tabs'

function GranularRoiPages({ activeButton, scrollToDiv }) {
    
    return (
        <div className='bootstrap-content'>
            <div className="row justify-content-center gx-0">
                <div className="col-12 col-lg-10 container-div">
                    <div className="layer-container">
                        <div className="tabs-container">
                            <Tabs activeButton={activeButton} scrollToDiv={scrollToDiv} />
                        </div>
                        <div className='tab-content-div'>
                            <div className="banner-section">
                                <div className="row g-lg-5 align-items-center">
                                    <div className="col-12 col-lg-7 content-div">
                                        <h1 className='heading'>This is how it works</h1>
                                        <h6 className='sub-heading'>1. 6-layer Deep Measurement</h6>
                                        <div className="sub-content-div">
                                            <p>Our granular ROI product delivers unparalleled insights through a 6-layer deep measurement approach:</p>
                                            <ul className='list-style'>
                                                <li>
                                                    <p>Brand Level: Overall brand performance and equity impact</p>
                                                </li>
                                                <li>  
                                                    <p>Campaign Level: Effectiveness of specific marketing initiatives </p>
                                                </li>
                                                <li>
                                                    <p>Media Level: Comparison across different media types (e.g., TV, digital, print)</p>
                                                </li>
                                                <li>
                                                    <p>Channel Level: Performance within each media (e.g., social platforms, search engines)</p>
                                                </li>
                                                <li>
                                                    <p>Platform Level: Granular insights on specific platforms (e.g., Facebook, Google)</p>
                                                </li>
                                                <li>
                                                    <p>Audience Level: ROI across different customer segments orbiting your brand</p>
                                                </li>
                                            </ul>
                                            
                                            <p>This multi-layered approach enables the identification of synergies and inefficiencies</p>
                                        </div>
                                    </div>
                                    <div className="col-12 col-lg-5 image-div text-lg-end">
                                        <img src={require('../../../assets/images/granular-roi/5.jpg')} alt="6-layer Deep Measurement" className='frames-image' />
                                    </div>
                                </div>
                            </div>
                            <div className="banner-section">
                                <div className="row g-lg-5 align-items-center">
                                    <div className="col-12 col-lg-5 image-div">
                                        <img src={require('../../../assets/images/granular-roi/6.jpg')} alt="Causal AI & Deep Learning" className='frames-image' />
                                    </div>
                                    <div className="col-12 col-lg-7 content-div">
                                        <h6 className='sub-heading'>2. Causal AI & Deep Learning</h6>
                                        <div className="sub-content-div">
                                            <p>Our Connected AI navigates the quantum realm of attribution across multiple levels (campaign, channel, audience):</p>
                                            <ul className='list-style'>
                                                <li>
                                                    <p>Isolate individual marketing effects: Precisely measure the impact of specific tactics and channels in your marketing microcosm</p>
                                                </li>
                                                <li>   
                                                    <p>Handle complex non-linear relationships: Capture nuanced interactions between marketing elements across dimensions</p>
                                                </li>
                                                <li>      
                                                    <p>Account for time-lagged effects: Understand both immediate and long-term impacts in your marketing timeline</p>
                                                </li>
                                                <li>    
                                                    <p>Adaptive learning: Continuously update models based on new data and market changes</p>
                                                </li>
                                                <li>      
                                                    <p>Counterfactual analysis: Simulate "what-if" scenarios for strategic planning across parallel marketing realities</p>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="banner-section">
                                <div className="row g-lg-5 align-items-center">
                                    <div className="col-12 col-lg-7 content-div">
                                        <h6 className='sub-heading'>3. Actionable Insights</h6>
                                        <div className="sub-content-div">
                                            <ul className='list-style-dot'>
                                                <li>
                                                    <p>Precise budget allocation: Optimize spend across channels, platforms, and campaigns </p>
                                                </li>
                                                <li>
                                                    <p>Audience targeting: Tailor strategies to the most responsive segments</p>
                                                </li>
                                                <li>
                                                    <p>Creative optimization: Identify high-performing content elements</p>
                                                </li>
                                                <li>
                                                    <p>Timing optimization: Determine ideal timing and frequency for maximum impact</p>
                                                </li>
                                                <li>
                                                    <p>Cross-channel synergies: Leverage insights on channel interactions for integrated strategies</p>
                                                </li>
                                                <li>
                                                    <p>Campaign refinement: Continuously improve based on granular performance data from every corner of your marketing universe</p>
                                                </li>
                                                <li>
                                                    <p>ROI forecasting: Predict outcomes of potential marketing scenarios across parallel realities</p>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-12 col-lg-5 image-div text-lg-end">
                                        <img src={require('../../../assets/images/granular-roi/7.jpg')} alt="Actionable Insights" className='frames-image' />
                                    </div>
                                </div>
                            </div>
                            <div className="banner-section">
                                <div className="row g-lg-5 align-items-center">
                                    <div className="col-12 col-lg-5 image-div">
                                        <img src={require('../../../assets/images/granular-roi/8.jpg')} alt="Improved ROI" className='frames-image' />
                                    </div>
                                    <div className="col-12 col-lg-7 content-div">
                                        <h6 className='sub-heading'>4. Improved ROI</h6>
                                        <div className="sub-content-div">
                                            <p>Identify high-performing platforms and audiences, leading to superior resource allocation and increased overall ROI</p>
                                            <ul className='list-style-dot'>
                                                <li>   
                                                    <p>Precision targeting: Focus resources on the most responsive segments</p>
                                                </li>
                                                <li>    
                                                    <p>Channel optimization: Allocate budget to best-performing channels</p>
                                                </li>
                                                <li>    
                                                    <p>Tactic refinement: Double down on strategies yielding highest returns</p>
                                                </li>
                                                <li>    
                                                    <p>Waste reduction: Eliminate or reduce low-impact activities draining your marketing energy</p>
                                                </li>
                                                <li>    
                                                    <p>Agile decision-making: Quickly adapt to performance insights from every corner of your marketing microverse</p>
                                                </li>
                                            </ul> 
                                            <p>With Data POEM's Connected Intelligence, navigate the intricate microverse of your marketing efforts. Uncover hidden value, optimize at the most granular level, and maximize ROI across every dimension of your marketing universe.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default GranularRoiPages
