import React, { useState } from 'react'
import "./mainscreen.scss"
import { MdKeyboardArrowDown, MdOutlineHome } from 'react-icons/md'
import { 
    // FaFileAlt, 
    FaTimes } from 'react-icons/fa'
import VideoPlayer from '../videoplayer/VideoPlayer';
import { useNavigate } from 'react-router-dom';

const faqs = [
    {
        title: 'What makes our AI, The AI ?',
        videoSrc: "https://placehold.co/400",
        content: (
            <div className='faq-content-div'>
                <p className='faq-sub-heading'>
                    The Cockpit is built on cutting-edge technology that elevates your marketing performance to new heights.
                </p>
                
                <p><strong>1. Deep Learning</strong> <br /> Our AI harnesses the power of deep learning to analyze vast amounts of data from various sources, uncovering hidden patterns and interactions that traditional MMMs might overlook.</p>
                    
                <p><strong>2. Neural Networks</strong> <br /> At the core of our AI are sophisticated neural network algorithms that mimic the human brain. This allows Data Poem to learn and adapt in real-time, constantly improving its ability to deliver actionable, data-driven insights.</p>
                   
                <p><strong>3. Explainable AI (XAI)</strong> <br /> Data Poem goes beyond just providing answers – it explains the "how" behind its conclusions. Utilizing XAI techniques, our AI unpacks its decision-making process, presenting it in a way that humans can easily grasp. Feature Attribution highlights which input features (data points) contribute most to a specific output, akin to understanding which ingredients have the strongest influence on a dish's flavor.</p>

                <p><strong>4. Causal AI:</strong> <br /> We don't stop at mere correlation; our AI identifies cause-and-effect relationships between marketing activities and customer behavior. It explains the "why" behind its conclusions, revealing why certain factors are considered more important and the causal relationships at play. Additionally, interventions simulate manipulating variables in the data to see how the network's outputs change, while counterfactuals analyze what would have happened if specific data points were different – enabling "what if" scenario analysis based on real data.</p>
 
                <p>Together, these cutting-edge technologies demystify the inner workings of neural networks, transforming them from opaque black boxes into transparent and interpretable models that drive business success. And the best part? Data Poem seamlessly integrates with your existing MMMs, without the need to replace your current setup.</p>    
            </div>
        )
    },
    {
        title: 'What’s The AI Cockpit? How does it help my brand grow?',
        videoSrc: 'https://placehold.co/400',
        content: (
            <div className='faq-content-div'>
                <p className='faq-sub-heading'>
                    Data. It's everywhere. Businesses collect mountains of it, from customer interactions and marketing campaigns to website analytics and sales figures. But here's the problem: this data often sits isolated in silos, like whispers in separate rooms.
                </p>
                        <p><strong>Analyzing siloed data provides a single, incomplete picture.</strong>  It's like trying to navigate by a single star – the direction might seem clear, but it could lead you wildly off course.</p>

                        <p><strong>Introducing the AI Cockpit: A Revolutionary Approach to Data Integration</strong> We've taken a revolutionary approach. We don't just collect data – we  <strong>collect, collate, harmonize, and unify</strong> it. Hundreds of data sources, seamlessly stitched together in a way never attempted before. This powerful engine allows the AI Cockpit to pull in a <strong>plethora</strong> of data sources, creating a holistic view that shatters the limitations of silos.</p>

                        <p><strong>It offers some unbeatable competitive advantages (XAI)</strong></p>
                        <ul className='list-style'>
                            <li>
                                <p className='mb-0'><strong>(Your MMM)<sup>Data Poem</sup> </strong></p>
                                <p className='mb-0'> Interactions reveal the collaborative efforts of marketing channels.</p>
                                <p> Measuring them shows how seemingly separate campaigns <br /> (social media ad, email with discount code) <br /> work together to drive conversions, <br /> providing a more accurate picture of ROI.</p>
                            </li>
                            <li>
                                <p className='mb-0'><strong>Measure Long-Term ROI</strong></p>
                                <p className='mb-0'> Data Poem’s algorithms account for delayed effects.</p>
                                <p> It can identify how a social media ad today influences <br />
                                    a website purchase weeks later, <br />
                                    providing a more accurate picture of long-term ROI.
                                </p>
                            </li>
                            <li>
                                <p className='mb-0'><strong>Unlock Retail Marketing ROI</strong></p>
                                <p className='mb-0'> Measure true ROAS and full-funnel performance across RMNs' walled gardens</p>
                                <p className='mb-0'> Measure retail media impressions impact on sales across multiple retailers, not just the featured one </p>
                                <p className='mb-0'> Comprehensive, omnichannel ROAS optimization of all shopper marketing and retail media spends  </p>
                                <p className='mb-0'> Agile Monthly measurement and optimization </p>
                                <p> Data drudgery of collecting and collating from multiple sources </p>
                            </li>
                            <li>
                                <p className='mb-0'><strong>Real-Time Optimization for the Win</strong></p>
                                <p className='mb-0'> Today’s marketing landscape changes in the blink of an eye.</p>
                                <p className='mb-0'> Data Poem continuously learns and adapts, <br />
                                    delivering real-time insights so you can stay ahead of the curve.
                                </p>
                            </li>
                        </ul>
            </div>
        )
    },
    {
        title: 'Why was it The Far Future ? Why is it 10 mins away now ? ',
        videoSrc: 'https://placehold.co/400',
        content: (
            <div className='faq-content-div'>
                <p>For decades, marketers have sought to unlock the secrets of customer behavior, leveraging analytics to gain insights that could drive more effective campaigns. However, traditional models and approaches often fell short, providing fragmented glimpses that failed to capture the full, multidimensional nature of human decision-making.</p>
                <p>But a seismic shift is underway, ushering in a new era of hyper-intelligent marketing analytics – one that promises to shatter the limitations of the past and propel brands into a future where customers feel truly understood, valued, and engaged on a deeper level.</p>
                <p>At the forefront of this revolution are cutting-edge technologies that were once the stuff of science fiction: Deep Learning, Neural Networks, Causal AI, and Explainable AI. Each of these advancements represents a powerful piece of the puzzle, working in harmony to unlock unprecedented levels of customer understanding and marketing precision.</p>
                <p>Deep Learning algorithms can process vast troves of data, uncovering intricate patterns and customer journeys that traditional models would overlook entirely. Neural Networks, modeled after the human brain's cognitive processes, can decipher the emotions, motivations, and nuances that drive customer actions, enabling hyper-personalized campaigns that resonate on a profound level.</p>
                <p>But understanding alone is not enough. Causal AI takes the next step, transcending mere correlation to pinpoint the precise factors that influence customer behavior, sales trends, and market shifts. No more guesswork – just clear, actionable insights into the underlying causes.</p>
                <p>And to foster trust and accountability, Explainable AI lifts the veil on complex algorithms, revealing the reasoning behind their recommendations in a way that humans can comprehend. This empowers marketers to make informed decisions, confident in the rationale driving their strategies.</p>
                <p>This future of hyper-intelligent marketing analytics is no longer a distant dream; it's a reality that Data Poem has been weaving together for the past five years. By integrating these groundbreaking technologies, we've created a platform that enables brands to connect with customers on a deeper level, delivering campaigns that truly resonate and drive business success.</p>
                <p>The era of hyper-intelligent marketing analytics is dawning, and the possibilities are limitless. Prepare to unlock a new level of customer understanding, precision, and engagement – because the future is just ten minutes away.</p>
            </div>
        ),
    },
    {
        title: 'Why is The AI Cockpit light years ahead of today ?',
        videoSrc: 'https://placehold.co/400',
        content: (
            <div className='faq-content-div'>
                <p>In the rapidly evolving marketing landscape, traditional tools like marketing mix models (MMMs) have fallen woefully behind, constrained by their reliance on historical data and linear modeling techniques. These legacy approaches offer a narrow, outdated view that fails to capture the rich complexities of modern customer behavior and the vast arrays of data that inform it.</p>
                <p>The AI Cockpit, however, represents a quantum leap forward, propelling marketers light years beyond the limitations of legacy tools and into a new frontier of hyper-intelligent analytics and unparalleled customer insights.</p>
                <p>While MMMs struggle to process and integrate vast, disparate data sources, The AI Cockpit seamlessly ingests and synthesizes troves of information through cutting-edge Deep Learning algorithms. These advanced neural networks uncover intricate patterns, customer journeys, and insights that would remain hidden to traditional models, unlocking a depth of understanding that was once unimaginable.</p>
                <p>Moreover, The AI Cockpit transcends mere correlation by harnessing the power of Causal AI. This groundbreaking technology goes beyond surface-level associations to pinpoint the precise factors that drive sales trends, market shifts, and customer decisions. With clear visibility into the underlying causes, marketers can abandon guesswork and embrace data-driven strategies that deliver tangible, measurable results.</p>
                <p>But The AI Cockpit's true genius lies in its ability to emulate the human mind's cognitive processes through advanced Neural Networks. These sophisticated algorithms decipher the emotions, motivations, and nuances that shape customer actions, enabling hyper-personalized campaigns that resonate on a profound level – a feat that legacy tools simply cannot match.</p>
                <p>Furthermore, The AI Cockpit fosters unprecedented trust and accountability through Explainable AI, which lifts the veil on complex algorithms, revealing the reasoning behind its recommendations in a way that humans can comprehend. This unparalleled transparency empowers marketers to make informed decisions with confidence, secure in the rationale driving their strategies.</p>
                <p>In an era where customer expectations and market dynamics are constantly evolving, the chasm between legacy tools and The AI Cockpit will only widen – a gulf separating those who embrace the future and those who remain tethered to the past. The AI Cockpit represents a paradigm shift, breaking free from the constraints of traditional approaches and propelling brands into a new era of hyper-intelligent customer engagement and unrivaled competitive advantage.</p>
                <p>The choice is clear: soar light years ahead with the hyper-intelligent capabilities of The AI Cockpit, or risk being left behind in the wake of this transformative revolution.</p>
            </div>
        ),
    },
    {
        title: 'If it’s already so advanced, why does it need me ?',
        videoSrc: 'https://placehold.co/400',
        content: (
            <div className='faq-content-div'>
                <p>While The AI Cockpit represents a groundbreaking leap in marketing analytics, it is important to understand that this advanced platform is not designed to replace human talent and expertise. Rather, it serves as a cockpit, amplifying the capabilities of marketing leaders and enabling them to soar to new heights of data-driven decision-making.</p>
                <p>At the heart of The AI Cockpit lies a fusion of cutting-edge technologies that process vast amounts of data, uncover intricate patterns, and provide unprecedented insights into customer behavior and market dynamics. However, it is the Human Head of Analytics who remains firmly in the pilot's seat, guiding the platform's capabilities and leveraging its insights to chart the course for successful marketing strategies.</p>
                <p>The AI Cockpit is not a black box that operates autonomously; it is a transparent and interpretable system that fosters trust and accountability through Explainable AI. This groundbreaking technology lifts the veil on complex algorithms, revealing the reasoning behind its recommendations in a way that human pilots can comprehend and scrutinize.</p>
                <p>Heads of Analytics bring invaluable domain expertise, strategic vision, and a deep understanding of their organization's unique goals and challenges. They leverage The AI Cockpit's advanced capabilities to gain a comprehensive view of the marketing landscape, but it is their human intelligence and decision-making prowess that ultimately shape the trajectory of their campaigns.</p>
                <p>Moreover, The AI Cockpit is designed to be a collaborative platform, seamlessly integrating with existing marketing mix models and data sources. This symbiotic relationship empowers Heads of Analytics to capitalize on their existing investments and knowledge, while simultaneously harnessing the power of hyper-intelligent analytics to uncover new opportunities and refine their strategies.</p>
                <p>In an era where customer expectations and market dynamics are constantly evolving, the role of the Head of Analytics becomes even more crucial. They are the ones who interpret The AI Cockpit's insights within the context of their organization's unique challenges and objectives, translating data into actionable strategies that drive tangible results.</p>
                <p>The AI Cockpit is a game-changing innovation, but it is not a replacement for human talent and leadership. It is a powerful cockpit that amplifies the capabilities of Heads of Analytics, enabling them to soar to new heights of data-driven decision-making and customer engagement, while remaining firmly in control as the pilots of their marketing strategies.</p>
            </div>
        ),
    },
    {
        title: " Why is it the world's first?",
        videoSrc: 'https://placehold.co/400',
        content: (
            <div className='faq-content-div'>
                <p>For too long, marketing analytics has been a battlefield of fragmented tools. Deep Learning offered glimpses into the future, but lacked actionable explanations. Neural Networks unearthed correlations, but couldn't pinpoint causality. Siloed Explainable AI existed, but often struggled with complex models, leaving a black box of "why" behind the "what."</p>
                <p><strong>Data Poem shatters these silos.</strong> We're the first to forge a revolutionary alliance, bringing these four titans of AI together in a unified platform: the  <strong>ai cockpit</strong>. This isn't just about having them all under one roof – it's about a fundamental shift in how they work together.</p>
                <ul className='list-style dot'>
                    <li>
                        <p><strong>From Hidden Patterns to Actionable Insights:</strong> Imagine a <strong>15% uplift in customer conversion rates.</strong> That's the potential of Deep Learning combined with Causal AI. Deep Learning in the ai cockpit sifts through vast amounts of data, uncovering previously hidden patterns in customer behavior. But unlike siloed approaches, Causal AI then steps in, not just showing <strong>correlations</strong>, but the <strong>causal relationships</strong> driving those patterns. This empowers you to move beyond "what" to a powerful "why," enabling targeted interventions that drive real results.</p>
                    </li>
                    <li>
                        <p><strong>Transparency You Can Trust:</strong> Traditional AI models often operate as black boxes. The AI Cockpit changes this with Explainable AI. We don't just tell you what action to take, we explain <strong>how</strong> the AI arrived at that recommendation, with clear, <strong>human-understandable</strong> justifications. This transparency builds trust and empowers you to confidently optimize your marketing strategies.</p>
                    </li>
                    <li>
                        <p><strong>Unified Power, Amplified Results:</strong> Siloed AI tools are like isolated islands. The ai cockpit bridges these divides, allowing these powerful AI techniques to work in <strong>concert</strong>. Deep Learning feeds insights to Causal AI, which in turn refines the focus for Deep Learning. This continuous loop unlocks a new level of understanding, leading to <strong>previously unimaginable</strong> marketing campaign effectiveness.</p>
                    </li>
                </ul>
                <p>The AI Cockpit isn't just another tool. It's a paradigm shift.</p>
            </div>
        ),
    },
    {
        title: "What makes it Connected?  Why Disconnected Data is Costing You (and How the ai cockpit Fixes It)",
        videoSrc: 'https://placehold.co/400',
        content: (
            <div className='faq-content-div'>
                <p>You're a head of analytics, data is your domain. But a familiar frustration lingers: siloed data sources. <strong>Industry reports</strong> estimate that disconnected marketing analytics leads to an average of <strong>15% wasted marketing spend</strong>. Fragmented insights offer glimpses, not the full picture. Imagine a <strong>360-degree view of your customer</strong>, fueled by seamlessly integrated data. That's the power of The AI Cockpit.</p>
                <p><strong>The Problem: Blind Spots and Misleading Correlations</strong></p>
                <p>Traditional analytics tools operate in isolation. <strong>Web analytics</strong> paints a picture of website behavior, but lacks context on social media engagement or email campaign performance. <strong>CRM data</strong> reveals customer interactions, but doesn't capture the broader market trends. </p>
                <p>This siloed approach leads to:</p>
                <ul className='list-style dot'>
                    <li>
                        <p><strong>Misleading Correlations:</strong> You see a correlation between ad exposure and website visits, but is it the ad driving traffic, or a seasonal trend? Without causal insights, you're left guessing.</p>
                    </li>
                    <li>
                        <p><strong>Hidden Patterns:</strong> Buried within disparate data sources could be valuable insights on customer behavior or market shifts. Siloed tools simply can't uncover these hidden gems.</p>
                    </li>
                    <li>
                        <p><strong>Wasted Resources:</strong> Fragmented insights lead to suboptimal campaign targeting and budget allocation. Industry reports suggest this can result in an average of <strong>15% wasted marketing spend</strong>.</p>
                    </li>
                </ul>
                <p className='mb-0'><strong>The Solution: The World's Most Advanced Connected Marketing Intelligence Platform</strong></p>
                <p>We shatter these silos. We go beyond data collection – we <strong>harmonize and unify</strong> information from <strong>hundreds of sources</strong>, including:</p>
                <ul className='list-style dot'>
                    <li>
                        <p>Web Analytics</p>
                    </li>
                    <li>
                        <p>CRM Data</p>
                    </li>
                    <li>
                        <p>Marketing Automation Platforms</p>
                    </li>
                    <li>
                        <p>Social Media Data</p>
                    </li>
                    <li>
                        <p>Advertising Platforms</p>
                    </li>
                    <li>
                        <p>Market Research Data</p>
                    </li>
                </ul>
                <p>This comprehensive data lake provides the foundation for our powerful AI engine.</p>
                <p>Here's how the ai cockpit breaks the mold: </p>
                <ul className='list-style dot'>
                    <li>
                        <p><strong>Deep Learning + Causal AI:</strong>Deep Learning uncovers hidden patterns in your unified data set. Causal AI goes beyond correlation, pinpointing the <strong>why</strong> behind customer actions. Imagine a <strong>10% uplift in conversion rates</strong> based on data-driven insights, not hunches.</p>
                    </li>
                    <li>
                        <p><strong>Explainable AI:</strong> The ai cockpit doesn't just tell you what to do, it explains <strong>how</strong> it arrived at its recommendations. This transparency builds trust and empowers you to make informed decisions.</p>
                    </li>
                    <li>
                        <p><strong>Unified Power, Amplified Results:</strong> Disparate AI tools are like isolated power plants. The ai cockpit connects them, allowing these powerful techniques to work in synergy. Imagine the potential for previously unimaginable campaign effectiveness.</p>
                    </li>
                </ul>
            </div>
        ),
    },
    {
        title: "Why Intelligence Crushes Data Alone: Real-World Examples of Marketing Magic",
        videoSrc: 'https://placehold.co/400',
        content: (
            <div className='faq-content-div'>
                <p>Data is king, but without context, it's a king lost in a labyrinth. Here's how the Data Poem ai cockpit transforms raw data into marketing intelligence that drives real results:</p>
                <p><strong>Usecase 1: From Correlation to Causation - Unlocking the Power of Location Data</strong></p>
                <p><strong>Data:</strong> You see a surge in website traffic on Mondays from a specific geographic area.</p>
                <p><strong>Intelligence:</strong> The AI Cockpit, using <strong>Causal AI</strong>, analyzes this data alongside weather patterns, social media trends, and local events. It reveals a causal link – a popular food blogger in that region just raved about your product on a Monday morning social media post.</p>
                <p><strong>Action:</strong> You leverage this intelligence to launch targeted social media ads in that region, capitalizing on the blogger's influence and maximizing the impact of the positive review.</p>
                <p><strong>Result:</strong> Increased conversions and brand awareness in a specific target market.</p>

                <p><strong>Usecase 2: Beyond the Click: Predicting Customer Lifetime Value</strong></p>
                <p><strong>Data:</strong> You see a high click-through rate on a particular email campaign.</p>
                <p><strong>Intelligence:</strong> The AI Cockpit utilizes <strong>Deep Learning</strong> to analyze customer purchase history, demographics, and past email engagement. It predicts a segment of those clicking are unlikely to become long-term customers.</p>
                <p><strong>Action:</strong> You tailor a re-engagement email campaign for this segment, offering personalized incentives to encourage repeat purchases and increase customer lifetime value.</p>
                <p><strong>Result:</strong> Increased customer retention and a more loyal customer base.</p>

                <p><strong>Usecase 3: From Likes to Lifestyles: Personalization at Scale</strong></p>
                <p><strong>Data:</strong> You see a high number of "likes" on social media posts featuring a specific product line.</p>
                <p><strong>Intelligence:</strong> The AI Cockpit uses <strong>Explainable AI</strong> to analyze the social media data alongside customer profiles and website behavior. It reveals the "likers" who are most likely to convert belong to a specific demographic with a particular interest in sustainability.</p>
                <p><strong>Action:</strong> You personalize future social media content and targeted ads to highlight the sustainable aspects of your product line, specifically appealing to this identified segment.</p>
                <p><strong>Result:</strong> Increased conversion rates and targeted brand messaging that resonates with your ideal customer.</p>
                <p>These are just a few examples of how the Data Poem ai cockpit transforms data into actionable intelligence. By understanding the "why" behind customer behavior, you can make data-driven decisions that deliver real-world marketing success.</p>
            </div >
        ),
    },
    {
        title: "Why The Hub : Why Unifying Hundreds of Data Sources is a Herculean Task (and Why It Matters)",
        videoSrc: 'https://placehold.co/400',
        content: (
            <div className='faq-content-div'>
                <p>Imagine a library. Not your local one with a few thousand books, but a sprawling labyrinth containing millions of volumes in dozens of languages, stacked haphazardly across countless shelves. That's the challenge of working with data from hundreds of sources – a vast ocean of information, but without a system to navigate it, utterly useless. Here's why unifying these sources is a complex task, and why it's diametrically opposed to simply having them:</p>
                <p><strong>The Data Silo Problem:</strong></p>
                <p>Traditional data analytics often relies on data silos – isolated repositories specific to a function, like marketing automation or CRM. This fragmented approach creates several problems:</p>
                <ul className='list-style dot'>
                    <li>
                        <p><strong>Inconsistency: </strong>Data formats and definitions can vary wildly between sources. Imagine a book with missing pages, conflicting chapter numbering, and entries written in different alphabets. Analyzing such data becomes an exercise in frustration.</p>
                    </li>
                    <li>
                        <p><strong>Incomplete Picture: </strong> Each silo offers a piece of the puzzle, but you lack the complete picture of your customer or market. It's like trying to understand a story by reading only half the chapters, scattered across different rooms.</p>
                    </li>
                    <li>
                        <p><strong>Limited Insights: </strong> Siloed data restricts the ability to identify hidden patterns and connections. It's like having a library with no librarian – the knowledge is there, but you can't access it effectively.</p>
                    </li>
                </ul>
                <p><strong>The Data Hub Advantage:</strong></p>
                <p>The AI Cockpit tackles these challenges head-on with a <strong>data hub</strong>. Think of it as the grand central station of your data universe, unifying hundreds of sources into a single, organized system. Here's how it makes all the difference:</p>
                <ul className='list-style dot'>
                    <li>
                        <p><strong>Standardization:</strong> The data hub acts as a translator, ensuring all data adheres to consistent formats and definitions. Imagine a team of librarians meticulously cataloging and organizing the library's collection.</p>
                    </li>
                    <li>
                        <p><strong>Completeness:</strong> The data hub aggregates information from all sources, providing a holistic view of your customer journey and market trends. It's like having all the books in one place, easily accessible for exploration and analysis.</p>
                    </li>
                    <li>
                        <p><strong>Powerful Insights:</strong> By unifying data, the ai cockpit can identify connections and patterns that would remain hidden in silos. Imagine a researcher effortlessly cross-referencing information across different disciplines, unlocking groundbreaking discoveries.</p>
                    </li>
                </ul>

                <p><strong>Simply Having Data vs. Actionable Intelligence: </strong></p>
                <p>Having hundreds of data sources is like having a massive library. But without a data hub, it's a library in disarray. The Data Poem ai cockpit acts as your data architect, transforming that unorganized collection into a treasure trove of actionable intelligence. It empowers you to:</p>
                <ul className='list-style dot'>
                    <li>
                        <p><strong>Make data-driven decisions:</strong> With a unified view, you can confidently base your marketing strategies on a complete picture of your customers and market.</p>
                    </li>
                    <li>
                        <p><strong>Optimize campaigns: </strong> Identify inefficiencies and areas for improvement across all your marketing channels.</p>
                    </li>
                    <li>
                        <p><strong>Predict future trends: </strong> Uncover hidden patterns to anticipate customer behavior and market shifts.</p>
                    </li>
                </ul>
                <p>The Data Poem ai cockpit doesn't just give you data – it equips you with the tools to transform it into marketing magic.</p>
            </div>
        ),
    },
];

const MainScreen = ({ activePage, setActivePage, setCanScroll }) => {
    const navigate = useNavigate();
    // Dropdown
    const [isOpen, setIsOpen] = useState(false);
    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const [contentVisible, setContentVisible] = useState(true);
    const [openIndex, setOpenIndex] = useState(null);
    const handleAccordionClick = (index) => {
        setOpenIndex(openIndex === index ? null : index);
    };
    // const handleReadNerdcaseClick = (e) => {
    //     e.stopPropagation();
    //     setContentVisible(!contentVisible);
    // };

    const AccordionItem = ({ title, videoSrc, content, index, isOpen, onAccordionClick, isOnlyVisible }) => (
        <div className={`faq-card ${isOpen ? 'expanded' : ''}`}
            onClick={() => {
                if (!isOpen) {
                    onAccordionClick()
                }
            }}>
            <div className={`faq-card-header ${isOnlyVisible ? 'visible' : 'hidden'}`}
                onMouseOver={() => {
                    if(!isOpen){
                        setCanScroll(false)
                    }
                }}
                onMouseLeave={() => {
                    if(!isOpen){
                        setCanScroll(true)
                    }
                }}
            >
                <h6 className='faq-card-content mb-0'>{title}</h6>
                {isOpen && (<div className='d-flex align-items-center'>
                    {/* <div className="bg-black d-flex align-items-center faq-button" onClick={handleReadNerdcaseClick} >
                        {contentVisible && <h6 className='mb-0'><img src={require('../../../assets/images/VideoIcon.png')} alt="" /> Watch a 2 minute video</h6>}
                        {!contentVisible && <h6 className='mb-0'><img src={require('../../../assets/images/FileTextIcon.png')} alt="" /> Read a 2-min nerdcase</h6>}
                    </div> */}
                    <FaTimes fontSize={'15px'} onClick={() => {
                        // setContentVisible(false);
                        setContentVisible(true);
                        onAccordionClick()
                    }} />
                </div>
                )}
            </div>
            {isOpen && (
                <div className="faq-answer">
                    {!contentVisible ? (
                        <div className='video-div'>
                            {/* <video src={videoSrc} controls className='w-100'></video> */}
                            {/* <video src={require('../../../assets/unbox-vid-1.mp4')} controls className='w-100'></video> */}
                            <VideoPlayer activePage={activePage} setActivePage={setActivePage} />
                        </div>
                    ) : (
                        <div
                            // onMouseOver={() => {
                            //     setCanScroll(false)
                            // }}
                            // onMouseLeave={() => {
                            //     setCanScroll(true)
                            // }}
                            onMouseOver={() => {
                                if(!isOpen){
                                    setCanScroll(false)
                                }
                            }}
                            onMouseLeave={() => {
                                if(!isOpen){
                                    setCanScroll(true)
                                }
                            }}
                        >
                            {content}
                        </div>
                    )}
                </div>
            )}

        </div>
    );

    return (
        <div className='bootstrap-content'>
            <div className="row justify-content-center gx-0">
                <div className="col-12 container-div">
                    <div className="unbox">
                        <div className="tabs">
                          <button
                              className={'btn btn-black home-btn text-white active'}
                                onClick={() => {
                                    setActivePage(4)
                                }}
                            >
                              <MdOutlineHome size={24} />
                            </button>
                            <button
                                className={`btn btn-black text-white`}
                                onClick={() => {
                                    setIsOpen(false)
                                    navigate('/granular-roi')
                                }}
                            >
                                Granular ROI
                            </button>
                            <button
                                className={`btn btn-black data-poem text-white`}
                                onClick={() => {
                                    setIsOpen(false)
                                    navigate('/marketing-mix-modelling')
                                }}
                            >
                                (Your MMM) <span className='text-right'>Data Poem</span>
                            </button>
                            <button
                                className={`btn btn-black text-white`}
                                onClick={() => {
                                    setIsOpen(false)
                                    navigate('/shopper-roi')
                                }}
                            >
                                Shopper ROI
                            </button>
                            <button
                                className={`btn btn-black text-white`}
                                onClick={() => {
                                    
                                    setIsOpen(false)
                                    navigate('/agile-monthly-roi')
                                }}
                            >
                                Agile Monthly ROI
                            </button>
                            <button
                                className={`btn btn-black text-white`}
                                onClick={() => {
                                    setIsOpen(false)
                                    navigate('/long-term-roi')
                                }}
                            >
                                Long-term ROI
                            </button>
                            <button
                                className={`btn btn-black text-white`}
                                onClick={() => {
                                    setIsOpen(false)
                                    navigate('/in-flight-optimization')
                                }}
                            >
                                In-flight Optimization
                            </button>
                            <div className="dropdown">
                                <button
                                    className={`btn btn-black ai-engine text-white`}
                                    type="button"
                                    onClick={toggleDropdown}
                                >
                                    The AI Engine
                                    <span
                                        className="arrow"
                                        style={{ transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)' }}
                                    >
                                        <MdKeyboardArrowDown />
                                    </span>
                                </button>
                                {isOpen && (
                                    <div className="dropdown-menu show">
                                        <button className={`dropdown-item`} onClick={() => {
                                            setIsOpen(false)
                                            navigate('/causal-ai')
                                        }}>Causal AI</button>
                                        <button className={`dropdown-item`} onClick={() => {
                                            setIsOpen(false)
                                            navigate('/explainable-ai')
                                        }}>Explainable AI</button>
                                        <button className={`dropdown-item`} onClick={() => {
                                            setIsOpen(false)
                                            navigate('/non-linear-optimization')
                                        }}>Non Linear Optimization</button>
                                    </div>
                                )}
                            </div>
                          <button
                              className={`btn btn-black text-white`}
                              onClick={() => {
                                setActivePage(7)
                              }}
                          >
                              The Data Hub
                          </button>
                        </div>
                        <div className='tab-content-div'>
                            <div className="banner-section px-0">
                                <div className="row align-items-center">
                                    <div className="col-12 col-lg-3 text-end">
                                        {/* <h6 className='heading mb-0'>LET’S <br />
                                            <span>Unbox</span> <br />
                                            THE <br />
                                            <span className='text-white'>heavy</span> <br />
                                            <span className='text-white'>words</span>
                                        </h6> */}
                                        <h6 className='heading mb-0'>GOT <br />
                                            <span>QUESTIONS</span> <br />
                                            BEFORE TAKING <br />
                                            THE LEAP <br />
                                            <span className='text-white'>WE'VE</span> <br />
                                            <span className='text-white'>GOT</span> <br />
                                            <span className='text-white'>ANSWERS</span>
                                        </h6>
                                    </div>
                                    <div className="col-12 col-lg-9 px-lg-3 justify-content-center">
                                        <div className="container">
                                            <div className='faq-accordion'>
                                                {faqs.map((faq, index) => (
                                                    <AccordionItem
                                                        key={'Faqs' + index}
                                                        title={faq.title}
                                                        video={faq.videoSrc}
                                                        content={faq.content}
                                                        isOpen={openIndex === index}
                                                        onAccordionClick={() => handleAccordionClick(index)}
                                                        isOnlyVisible={openIndex === null || openIndex === index}
                                                    />
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MainScreen
