import React from 'react'
import "../../../css/bootstrap.scss"
import "../../../css/component.scss"
import "../../../css/faq-accordion.scss"
import Tabs from '../../tabs/Tabs';

function InflightOptimization({ activeButton, scrollToDiv }) {

    return (
        <div className='bootstrap-content'>
            <div className="row justify-content-center gx-0">
                <div className="col-12 col-lg-10 container-div">
                    <div className="layer-container">
                        <Tabs activeButton={activeButton} scrollToDiv={scrollToDiv} />
                        <div className='tab-content-div'>
                            <div className="banner-section">
                                <div className="row g-lg-5 align-items-center">
                                    <div className="col-12 col-lg-7 content-div">
                                        <h1 className='heading'>This is how it works</h1>
                                        <h6 className='sub-heading'>1. Real-time Data Ingestion and Processing</h6>
                                        <div className="sub-content-div">
                                            <p>We harness the power of scalable data pipelines and distributed computing frameworks to manage the high volume and velocity of marketing data streams flowing through your universe.</p>
                                            <ul className='list-style-dot'>
                                                <li><p>Stream processing and event-driven architectures continuously ingest data</p></li>
                                                <li><p>Micro-batching ensures no valuable data point is left behind</p></li>
                                                <li><p>Advanced data preprocessing and feature engineering transform raw data into meaningful representations suitable for downstream machine learning models</p></li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-12 col-lg-5 text-lg-end image-div">
                                        <img src={require('../../../assets/images/inflight/21.jpg')} alt="Real-time Data Ingestion and Processing" className='frames-image' />
                                    </div>
                                </div>
                            </div>
                            <div className="banner-section">
                                <div className="row g-lg-5 align-items-center">
                                    <div className="col-12 col-lg-5 image-div">
                                        <img src={require('../../../assets/images/inflight/22.jpg')} alt="Non-linear Modeling and Adaptive Optimization" className='frames-image' />
                                    </div>
                                    <div className="col-12 col-lg-7 content-div">
                                        <h6 className='sub-heading'>2. Non-linear Modeling and Adaptive Optimization</h6>
                                        <div className="sub-content-div">
                                            <p>Our deep neural network architectures act as cosmic observatories, automatically learning hierarchical representations and temporal dynamics from high-dimensional data.</p>
                                            <ul className="list-style-dot">
                                                <li><p>Online learning and transfer learning techniques enable our deep learning models to continuously adapt and fine-tune their parameters as new data emerges</p></li>
                                                <li><p>Our models evolve with your dynamic marketing environment, ensuring optimization recommendations remain relevant and accurate across all dimensions</p></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="banner-section">
                                <div className="row g-lg-5 align-items-center">
                                    <div className="col-12 col-lg-7 content-div">
                                        <h6 className='sub-heading'>3. Interpretable Insights and Counterfactual Reasoning</h6>
                                        <div className="sub-content-div">
                                            <p>Our causal inference and effect estimation capabilities allow our engine to simulate the potential impact of interventions or changes to marketing strategies during active campaigns.</p>
                                            <ul className="list-style-dot">
                                                <li><p>Gain not just accurate predictions, but interpretable explanations for every recommendation</p></li>
                                                <li><p>Understand the cause-and-effect relationships driving your marketing performance</p></li>
                                                <li><p>Make informed decisions with clarity and confidence in your complex marketing ecosystem</p></li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-12 col-lg-5 text-lg-end image-div">
                                        <img src={require('../../../assets/images/inflight/23.jpg')} alt="Interpretable Insights and Counterfactual Reasoning" className='frames-image' />
                                    </div>
                                </div>
                            </div>
                            <div className="banner-section">
                                <div className="row g-lg-5 align-items-center">
                                    <div className="col-12 col-lg-5 image-div">
                                        <img src={require('../../../assets/images/inflight/24.jpg')} alt="Closed-loop Optimization and Decision Support" className='frames-image' />
                                    </div>
                                    <div className="col-12 col-lg-7 content-div">
                                        <h6 className='sub-heading'>4. Closed-loop Optimization and Decision Support</h6>
                                        <div className="sub-content-div">
                                            <p>We employ advanced decision support techniques like multi-objective optimization to automatically discover optimal marketing strategies that balance multiple objectives.</p>
                                            <ul className='list-style-dot'>
                                                <li><p>Human-in-the-loop interfaces and visualization tools enable you to interact with the optimization engine</p></li>
                                                <li><p>Provide feedback and make informed decisions as you navigate your marketing universe</p></li>
                                                <li><p>Achieve a perfect balance between AI-driven insights and human expertise</p></li>
                                            </ul>
                                            <p>With Data POEM's Connected Intelligence, take control of your marketing in real-time.</p>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default InflightOptimization
