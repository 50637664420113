import React from 'react'
import "./screen6.scss"

function Screen6({ activePage, setActivePage }) {

  return (
    <div className={'full-screen screen6'} key="screen6">
      <div className="bg-screen"></div>
      <div className="mask"></div>
      <div className="ellipse"></div>
      <div className="content about-700-div">
        <h3 className='redText'>Our Mission</h3>
        <p className='para'>(Marketing Imagination)<sup>ConnectedAI</sup></p>
        <h3 className='redText'>Our Genesis</h3>
        <p className='para'>
        In an interconnected consumer world<br />
        traditional measurement techniques created isolated views, <br />
        leaving potential synergies undiscovered.
        </p>
        <p className='para'>
        We are the connectors, <br />
        bridging gaps to reveal the full picture.
        </p>
        <p className='para'>
        Through relentless innovation and AI, <br />
        we're dismantling outdated paradigms, <br />
        revolutionizing marketing measurement <br />
        through connected insights
        </p>
        <h3 className='redText'>Our Guiding Stars</h3>
        <p className='para'>Courage | Innovation | Collaboration</p>
      </div>
    </div>
  )
}

export default Screen6