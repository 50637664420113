import React, { useState } from 'react'
import "./mainscreen.scss"
import { MdChevronRight, MdKeyboardArrowDown, MdOutlineHome } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'

const MainScreen = ({activePage, setActivePage}) => {
    const navigate = useNavigate()
    const [isOpen, setIsOpen] = useState(false);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div className='bootstrap-content'>
            <div className="row justify-content-center gx-0">
                <div className="col-12 col-lg-10 container-div">
                    <div className="dataHub">
                        <div className="tabs">
                          <button
                              className={'btn btn-black home-btn text-white'}
                                onClick={() => {
                                    setActivePage(4)
                                }}
                            >
                              <MdOutlineHome size={24} />
                            </button>
                            <button
                                className={`btn btn-black text-white`}
                                onClick={() => {
                                    setIsOpen(false)
                                    navigate('/granular-roi')
                                }}
                            >
                                Granular ROI
                            </button>
                            <button
                                className={`btn btn-black data-poem text-white`}
                                onClick={() => {
                                    setIsOpen(false)
                                    navigate('/marketing-mix-modelling')
                                }}
                            >
                                (Your MMM) <span className='text-right'>Data Poem</span>
                            </button>
                            <button
                                className={`btn btn-black text-white`}
                                onClick={() => {
                                    setIsOpen(false)
                                    navigate('/shopper-roi')
                                }}
                            >
                                Shopper ROI
                            </button>
                            <button
                                className={`btn btn-black text-white`}
                                onClick={() => {
                                    
                                    setIsOpen(false)
                                    navigate('/agile-monthly-roi')
                                }}
                            >
                                Agile Monthly ROI
                            </button>
                            <button
                                className={`btn btn-black text-white`}
                                onClick={() => {
                                    setIsOpen(false)
                                    navigate('/long-term-roi')
                                }}
                            >
                                Long-term ROI
                            </button>
                            <button
                                className={`btn btn-black text-white`}
                                onClick={() => {
                                    setIsOpen(false)
                                    navigate('/in-flight-optimization')
                                }}
                            >
                                In-flight Optimization
                            </button>
                            <div className="dropdown">
                                <button
                                    className={`btn btn-black ai-engine text-white`}
                                    type="button"
                                    onClick={toggleDropdown}
                                >
                                    The AI Engine
                                    <span
                                        className="arrow"
                                        style={{ transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)' }}
                                    >
                                        <MdKeyboardArrowDown />
                                    </span>
                                </button>
                                {isOpen && (
                                    <div className="dropdown-menu show">
                                        <button className={`dropdown-item`} onClick={() => {
                                            setIsOpen(false)
                                            navigate('/causal-ai')
                                        }}>Causal AI</button>
                                        <button className={`dropdown-item`} onClick={() => {
                                            setIsOpen(false)
                                            navigate('/explainable-ai')
                                        }}>Explainable AI</button>
                                        <button className={`dropdown-item`} onClick={() => {
                                            setIsOpen(false)
                                            navigate('/non-linear-optimization')
                                        }}>Non Linear Optimization</button>
                                    </div>
                                )}
                            </div>
                          <button
                              className={`btn btn-black text-white active`}
                              onClick={() => {
                                setActivePage(7)
                              }}
                          >
                              The Data Hub
                          </button>
                        </div>
                        <div className='tab-content-div'>
                            <div className="banner-section">
                                  <div className="row align-items-start justify-content-end">
                                      <div className="col-12 w-lg-70 w-80 content-div data-div">
                                          <h6 className='heading'>The Data Hub: Unifying your Marketing Universe</h6>
                                          <p className='heading-para'>
                                            In a fragmented data landscape,<br /> 
                                            businesses amass vast quantities from countless sources.<br /> 
                                            The challenge: Data silos create a fractured view of customer journeys.<br /> 
                                            We've forged connections among hundreds of sources, <br />
                                            crafting a unified, 360-degree view of your marketing universe.<br /> 
                                          </p>
                                          <button className='dive-btn'
                                            onClick={() => {
                                                navigate('/the-data-hub')
                                            }}
                                          >Discover True Integration <MdChevronRight /></button>
                                      </div>
                                  </div>
                              </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MainScreen
