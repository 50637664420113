import React, { useEffect, useRef, useState } from 'react'
import "./screen21.scss"
// import backgroundVideo from "../../assets/cockpit-video.mp4"

function Screen21({activePage, setActivePage, growthScreen, setGrowthScreen}) {
  const videoRef = useRef(null);
  const [isLoaded, setIsLoaded] = useState(false);

    return (
        <div className={'full-screen screen21'} key="screen21">
            {/* <div className="background-image"></div> */}
            {/* {!isLoaded &&  */}
            <div className="background-image"></div>
            {/* } */}
            {/* <video
                className={isLoaded ? "background-video" : "d-none"}
                ref={videoRef}
                src={backgroundVideo}
                onLoadedData={() => { setIsLoaded(true) }}
                autoPlay loop muted>
            </video> */}
            <div className="mask"></div>
            {/* <div className="cockpit-top"></div>
            <div className="cockpit-bottom"></div> */}
            <div className="ellipse"></div>
            <div className="content">
                <div className="text-center taic-div">
                    <img className={growthScreen === 1 ? 'largeImage' : 'smallImage'} src={require('../../assets/images/TAIC.png')} alt="" />
                </div>
                {growthScreen !== 1 && <div className="text-center growth-desc">
                    <p className='text-white'>CONNECTED INTELLIGENCE HUB</p>
                    <img className='arrow' src={require('../../assets/images/chevron-down.png')} alt="" />
                    <h2>Connected Growth Planning</h2>
                </div>}
                {growthScreen === 3 && <div className="text-center">
                    <img className='contentImage' src={require('../../assets/images/Gp-botoom.png')} alt="" />
                </div>}
                {/* <h4 className="text">
                    It's a unified growth planning tool. <br />
                    Measure the total incremental impact of interconnected business drivers: <br />
                    Brand Marketing, Shopper Marketing, Commerce Marketing, Trade Promotions, and more.<br />
                    Achieve 90%+ forecasting accuracy with our Non-linear Multivariate Forecasting and Optimization engine.
                </h4> */}
            </div>
        </div>
    )
}

export default Screen21