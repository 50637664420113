import React from 'react'
// { useEffect, useRef, useState } 

import "./screen7.scss"
import MainScreen from './mainscreen/mainscreen'
import { useNavigate } from 'react-router-dom'
// import backgroundVideo from "../../assets/contact-video-bg.mp4"

function Screen7({ activePage, setActivePage, scrollToDiv }) {
  const navigate = useNavigate()
  // const videoRef = useRef(null);
  // const [playRate, setPlayRate] = useState(1);
  // const [isLoaded, setIsLoaded] = useState(false)

  // useEffect(() => {
  //   if (videoRef.current) {
  //     videoRef.current.playbackRate = playRate;
  //   }
  // }, [playRate]);

  return (
    <div className={'full-screen screen7'} key="screen7">
      {/* {!isLoaded &&  */}
      <div className="bg-screen"></div>
      {/* } */}
      {/* <video
        className={isLoaded ? "background-video" : "d-none"}
        ref={videoRef}
        src={backgroundVideo}
        onPlay={() => { setPlayRate(1) }}
        onLoadedData={() => { setIsLoaded(true) }}
        autoPlay loop muted>
      </video> */}
      <div className="mask"></div>
      {/* <div className="ellipse"></div> */}
      <div className="content">
        <MainScreen activePage={activePage} setActivePage={setActivePage} scrollToDiv={scrollToDiv} />
      </div>
      <div className="bottom-center">
        <div className='bootstrap-content'>
          <div className="d-flex flex-column align-items-center gap-0 pointer" onClick={() => { 
            navigate('/');
            scrollToDiv(0);
          }}>
            <p className='start-text text-center text-white'>Start</p>
            <div>
              <img src={require('../../assets/images/chevron-down.png')} className='upArrow' alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Screen7