import React, { useRef, useState } from 'react'
import "./screen2.scss"
import backgroundVideo from "../../assets/cockpit-video.mp4"

function Screen2({ activePage, setActivePage }) {
    const videoRef = useRef(null);
    const [isLoaded, setIsLoaded] = useState(false);

    return (
        <div className={'full-screen screen2'} key="screen2">
            {/* {!isLoaded && 
             <div className="background-image"></div>
            } */}
            <video
                className={isLoaded ? "background-video" : "d-none"}
                ref={videoRef}
                src={backgroundVideo}
                onLoadedData={() => { setIsLoaded(true) }}
                autoPlay loop muted>
            </video>
            {/* <div className="background-image"></div> */}
            {/* For Linear-gradient */}
            <div className="mask"></div>
            {/*Header  */}
            <div className="cockpit-top"></div>
            {/* Bottom */}
            <div className="cockpit-bottom"></div>
            <div className="ellipse"></div>
            <div className="content">
                <h3 className="text">Navigate the Connected Marketing Intelligence Universe</h3>
                <div className="text-center mt-20">
                    <img src={require('../../assets/images/TAIC.png')} alt="" className='subImg' />
                </div>
            </div>
        </div>
    )
}

export default Screen2