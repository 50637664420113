import React, { useRef, useEffect, useState } from 'react'
import "./screen22.scss"
import { MdChevronRight } from 'react-icons/md';
import backgroundVideo from "../../assets/cockpit-video.mp4"
import { useNavigate } from 'react-router-dom';

function Screen22({activePage, setActivePage}) {
  const navigate = useNavigate();
  const videoRef = useRef(null);
  const [isLoaded, setIsLoaded] = useState(false);

    const [shownTab, setShownTab] = useState('');

    useEffect(() => {
        return () => {
            setShownTab('');
        }
    }, [])
    
    return (
        <div className={'full-screen screen22'} key="screen22">
            {!isLoaded && 
            <div className="background-image"></div>
            }
            <video
                className={isLoaded ? "background-video" : "d-none"}
                ref={videoRef}
                src={backgroundVideo}
                onLoadedData={() => { setIsLoaded(true) }}
                autoPlay loop muted>
            </video>
            <div className="mask"></div>
            <div className="cockpit-top"></div>
            <div className="cockpit-bottom"></div>
            <div className="ellipse"></div>
            <div className="bootstrap-content content">
                <h3 className="text mb-0">
                    Explore new dimensions of Connected Analytics
                </h3>
                <h4 className="text">
                    Select Your Mission Objective:
                </h4>
                <div className="container-fluid allTabs">
                    <div className='row align-items-center justify-content-center'>
                        <div className="col-12 col-lg-7">
                            <div className="row">
                                <div className="col-12 col-lg-4 text-center">
                                    <div className={`button-cust mx-lg-auto text-white right ${shownTab === '' ? 'granular' : ''}`} onMouseOver={() => {
                                        setShownTab('dataPoem');
                                    }}>
                                        <span>(Your MMM) <sup>Data Poem</sup></span>
                                        <div className={`roi-card text-start ${shownTab === 'dataPoem' ? 'shown' : 'hidden'}`} onMouseLeave={() => {setShownTab('');}}>
                                            <p>
                                                MMMs are powerful navigational tools, charting high-level insights across the marketing cosmos.<br />
                                                Yet, they have a few blindspots like measuring complex interactions and long-term ROI trajectories. <br />
                                                Data POEM's Connected Intelligence amplifies MMMs, leveraging the strengths of both methods
                                            </p>
                                            <button
                                                onClick={() => {
                                                    navigate('marketing-mix-modelling')
                                                }}
                                            >SEE HOW <MdChevronRight className={'right-chev-icon'} /></button>
                                        </div>
                                        <div className="after">
                                            <div className="pulse"></div>
                                            <div className="pulse"></div>
                                            <div className="pulse"></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-4 text-center mt-lg-4">
                                    <div className="button-cust mx-lg-auto text-white left" onMouseOver={() => {
                                        // document.getElementById('shake-div').classList.remove('granular');
                                        setShownTab('shopper')}}>
                                        <span>Shopper ROI</span>
                                        <div className={`roi-card text-start ${shownTab === 'shopper' ? 'shown' : 'hidden'}`} onMouseLeave={() => {setShownTab('');}}>
                                            <p>
                                                Connecting in-store purchases and shopper behavior to ROAS measurement remains an uncharted territory.<br />
                                                Our Connected Intelligence provides unprecedented insights into the performance of your offline shopper marketing, retail media networks, and coupon campaigns.
                                            </p>
                                            <button
                                                onClick={() => {
                                                    navigate('shopper-roi')
                                                }}
                                            >SEE HOW <MdChevronRight className={'right-chev-icon'} /></button>
                                        </div>
                                        <div className="before">
                                            <div className="pulse"></div>
                                            <div className="pulse"></div>
                                            <div className="pulse"></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-4 text-center mt-lg-2">
                                    <div className="button-cust text-white left mx-lg-auto" onMouseOver={() => {
                                        // document.getElementById('shake-div').classList.remove('granular');
                                        setShownTab('longTerm')}}>
                                        <span>Long-term ROI</span>
                                        <div className={`roi-card text-start ${shownTab === 'longTerm' ? 'shown' : 'hidden'}`} onMouseLeave={() => {setShownTab('');}}>
                                            <p>
                                            In the vast expanse of marketing analytics, measuring long-term ROI alongside short-term metrics is crucial for sustainable business growth. We harness the power of causal AI to accurately attribute long-term value to marketing activities, enabling better strategic decisions and optimized resource allocation across your marketing universe.
                                            </p>
                                            <button
                                                onClick={() => {
                                                    navigate('long-term-roi')
                                                }}
                                            >SEE HOW <MdChevronRight className={'right-chev-icon'} /></button>
                                        </div>
                                        <div className="before">
                                            <div className="pulse"></div>
                                            <div className="pulse"></div>
                                            <div className="pulse"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-lg-4 mt-xxl-5 justify-content-end">
                                <div className="col-12 col-lg-4 text-center mt-lg-3">
                                    <div className="button-cust mx-lg-auto text-white right" onMouseOver={() => {
                                        // document.getElementById('shake-div').classList.remove('granular');
                                        setShownTab('granular')}}>
                                        <span>Granular ROI</span>
                                        <div className={`roi-card text-start ${shownTab === 'granular' ? 'shown' : 'hidden'}`} onMouseLeave={() => {setShownTab('');}}>
                                            <p>
                                            In the vast expanse of the marketing universe, investments are made at a highly granular level, targeting specific audience clusters and platform constellations. Yet, ROI is often measured at broad media and channel level, resulting in underreported ROI. <br />
                                            We harness the power of Causal AI and Deep Learning techniques to provide unprecedented depth in ROI measurement, illuminating every corner of your marketing microverse.
                                            </p>
                                            <button
                                                onClick={() => {
                                                    navigate('granular-roi')
                                                }}
                                            >SEE HOW <MdChevronRight className={'right-chev-icon'} /></button>
                                        </div>
                                        <div className="after">
                                            <div className="pulse"></div>
                                            <div className="pulse"></div>
                                            <div className="pulse"></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-6 mt-lg-4">
                                    <div className="button-cust text-white left mx-lg-auto" onMouseOver={() => {
                                        // document.getElementById('shake-div').classList.remove('granular');
                                        setShownTab('agile')}}>
                                        <span>Agile Monthly ROI</span>
                                        <div className={`roi-card text-start ${shownTab === 'agile' ? 'shown' : 'hidden'}`} onMouseLeave={() => {setShownTab('');}}>
                                            <p>
                                                Traditional measurement methods are like ancient astronomers, taking several months to collect data, cleanse and model. By the time this ROI data reaches your marketing command center, it's as outdated as last year's star charts.<br />
                                                We harness the power of Causal AI and transfer learning techniques to deliver monthly ROI models with just a 2-week lag. 
                                            </p>
                                            <button
                                                onClick={() => {
                                                    navigate('agile-monthly-roi')
                                                }}
                                            >SEE HOW <MdChevronRight className={'right-chev-icon'} /></button>
                                        </div>
                                        <div className="before">
                                            <div className="pulse"></div>
                                            <div className="pulse"></div>
                                            <div className="pulse"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-lg-2 col-xl-3">
                            <div className="button-cust text-white left" onMouseOver={() => {
                                // document.getElementById('shake-div').classList.remove('granular');
                                setShownTab('inFlight')}}>
                                <span>In-flight Optimisation</span>
                                <div className={`roi-card text-start ${shownTab === 'inFlight' ? 'shown' : 'hidden'}`} onMouseLeave={() => {setShownTab('');}}>
                                    <p>
                                    Traditional models often rely on linear regression, becoming computationally intensive and difficult to scale as the number of variables and interactions in your marketing universe expand.<br />
                                    Our Connected Intelligence captures non-linear relationships, complex interactions, and adapts to dynamic environments, enabling real-time, in-flight optimization.
                                    </p>
                                    <button
                                        onClick={() => {
                                            navigate('in-flight-optimization')
                                        }}
                                    >SEE HOW <MdChevronRight className={'right-chev-icon'} /></button>
                                </div>
                                <div className="before">
                                    <div className="pulse"></div>
                                    <div className="pulse"></div>
                                    <div className="pulse"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="bottom-center">
                <div className='bootstrap-content'>
                    <div className='text-center'>
                        <div className='mb-2'>
                            <img src={require('../../assets/images/outcome/bottom-img.png')} alt="" className='center-right' />
                        </div>
                        <div onClick={() => { setActivePage(5) }}>
                            <img src={require('../../assets/images/chevron-down.png')} className='downArrow' alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Screen22