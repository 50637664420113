import React, { useRef, useState } from 'react'
import "./screen20.scss"
import backgroundVideo from "../../assets/cockpit-video.mp4"

function Screen20({activePage, setActivePage}) {
  const videoRef = useRef(null);
  const [isLoaded, setIsLoaded] = useState(false);

  return (
    <div className={'full-screen screen20'} key="screen20">
      {!isLoaded && 
       <div className="background-image"></div>
      }
      <video
          className={isLoaded ? "background-video" : "d-none"}
          ref={videoRef}
          src={backgroundVideo}
          onLoadedData={() => { setIsLoaded(true) }}
          autoPlay loop muted>
      </video>
      {/* <div className="background-image"></div> */}
      <div className="mask"></div>
      <div className="cockpit-top"></div>
      <div className="cockpit-bottom"></div>
      <div className="ellipse"></div>
      <div className="content">
        <div className="text-center">
          <img src={require('../../assets/images/TAIC.png')} alt="" className='topImg' />
        </div>
        <h4 className="text">Earth's Most Advanced <br /> Connected Marketing Intelligence Hub</h4>
      </div>
    </div>
  )
}

export default Screen20