import React, { useState } from 'react'
import "./mainscreen.scss"
import "./responsive.scss"
import { MdChevronRight, MdKeyboardArrowDown, MdOutlineHome } from 'react-icons/md'
import { useNavigate } from 'react-router-dom';

const MainScreen = ({ activePage, setActivePage }) => {
    const navigate = useNavigate()
    const [isOpen, setIsOpen] = useState(false);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div className='bootstrap-content'>
            <div className="row justify-content-center gx-0">
                <div className="col-12 col-lg-10 container-div">
                    <div className="ai-engine2">
                        <div className="tabs">
                          <button
                              className={'btn btn-black home-btn text-white'}
                                onClick={() => {
                                    setActivePage(4)
                                }}
                            >
                              <MdOutlineHome size={24} />
                            </button>
                            <button
                                className={`btn btn-black text-white`}
                                onClick={() => {
                                    setIsOpen(false)
                                    navigate('/granular-roi')
                                }}
                            >
                                Granular ROI
                            </button>
                            <button
                                className={`btn btn-black data-poem text-white`}
                                onClick={() => {
                                    setIsOpen(false)
                                    navigate('/marketing-mix-modelling')
                                }}
                            >
                                (Your MMM) <span className='text-right'>Data Poem</span>
                            </button>
                            <button
                                className={`btn btn-black text-white`}
                                onClick={() => {
                                    setIsOpen(false)
                                    navigate('/shopper-roi')
                                }}
                            >
                                Shopper ROI
                            </button>
                            <button
                                className={`btn btn-black text-white`}
                                onClick={() => {
                                    
                                    setIsOpen(false)
                                    navigate('/agile-monthly-roi')
                                }}
                            >
                                Agile Monthly ROI
                            </button>
                            <button
                                className={`btn btn-black text-white`}
                                onClick={() => {
                                    setIsOpen(false)
                                    navigate('/long-term-roi')
                                }}
                            >
                                Long-term ROI
                            </button>
                            <button
                                className={`btn btn-black text-white`}
                                onClick={() => {
                                    setIsOpen(false)
                                    navigate('/in-flight-optimization')
                                }}
                            >
                                In-flight Optimization
                            </button>
                            <div className="dropdown">
                                <button
                                    className={`btn btn-black ai-engine text-white active`}
                                    type="button"
                                    onClick={toggleDropdown}
                                >
                                    The AI Engine
                                    <span
                                        className="arrow"
                                        style={{ transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)' }}
                                    >
                                        <MdKeyboardArrowDown />
                                    </span>
                                </button>
                                {isOpen && (
                                    <div className="dropdown-menu show">
                                        <button className={`dropdown-item`} onClick={() => {
                                            setIsOpen(false)
                                            navigate('/causal-ai')
                                        }}>Causal AI</button>
                                        <button className={`dropdown-item`} onClick={() => {
                                            setIsOpen(false)
                                            navigate('/explainable-ai')
                                        }}>Explainable AI</button>
                                        <button className={`dropdown-item`} onClick={() => {
                                            setIsOpen(false)
                                            navigate('/non-linear-optimization')
                                        }}>Non Linear Optimization</button>
                                    </div>
                                )}
                            </div>
                          <button
                              className={`btn btn-black text-white`}
                              onClick={() => {
                                setActivePage(7)
                              }}
                          >
                              The Data Hub
                          </button>
                        </div>
                            <div className='tab-content-div'>
                                <div className="banner-section">
                                    <div className="row gx-0 align-items-start justify-content-end">
                                        <div className="col-12 w-lg-70 content-div ai-700-div">
                                            <h6 className='heading'>AI based optimization</h6>
                                            <p className='heading-para'>
                                                AI-driven Interconnected Optimization. Our engine excels in Multivariate non-linear optimization across channels, <br />
                                                the key to unlocking synergies.
                                            </p>
                                            <ul className='text-white'>
                                                <li>
                                                    <p className='heading-para'>Achieve 90%+ forecasting accuracy</p>
                                                </li>
                                                <li>
                                                    <p className='heading-para'>Map complex, non-linear relationships between diverse factors and desired outcomes</p>
                                                </li>
                                                <li>
                                                    <p className='heading-para'>Optimize strategies across intricate dependencies and synergies</p>
                                                </li>
                                                <li>
                                                    <p className='heading-para'>Transcend siloed saturation curves</p>
                                                </li>
                                                <li>
                                                    <p className='heading-para'>Unlock the complexity of modern marketing's interconnected realities</p>
                                                </li>
                                            </ul>
                                            <div className="sub-content-div">
                                                <div className='btn-container d-flex'>
                                                    <button className='dive-btn d-flex align-items-center'
                                                        onClick={() => {
                                                            navigate('/non-linear-optimization')
                                                        }}
                                                    ><span>Dive Deep into <br /> NON-LINEAR Optimization </span> <MdChevronRight /></button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MainScreen
