import React, { useEffect } from 'react'
import "./screen8.scss"
import MainScreen from './mainscreen/mainscreen';

function Screen8({ activePage, setActivePage, setCanScroll }) {

    useEffect(() => {setCanScroll(true)}, [])

    return (
        <div className={'full-screen screen8'} key="screen8">
            <div className="bg-screen"></div>
            <div className="mask"></div>
            <div className="ellipse"></div>
            <div className="content bootstrap-content" key={'activepage-'+activePage}>
                <MainScreen activePage={activePage} setActivePage={setActivePage} setCanScroll={setCanScroll} />
            </div>
        </div>
    )
}

export default Screen8