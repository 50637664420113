import React from 'react'
import "../../css/bootstrap.scss"
import "../../css/component.scss"
import "../../css/faq-accordion.scss"
import "./privacycomponent.scss"
import { Link } from 'react-router-dom'

function PrivacyComponent() {

    return (
        <div className='bootstrap-content privacy-page'>
            <div className="row justify-content-center gx-0">
                <div className="col-12 col-lg-10 container-div">
                    <div className="layer-container">
                        <div className='tab-content-div'>
                            <div className="faq-section">
                                <div className="col-12">
                                    <h4 className='privacy-heading'>Privacy Statement for DataPOEM</h4>
                                    <h6 className='effective-date'>Effective Date: 10-Sept-2024</h6>
                                    <h5 className='text'>At DataPOEM, we are committed to protecting your privacy. This Privacy Statement explains how we collect, use, share, and protect your personal information.</h5>

                                    <ul className='list-style'>
                                        <li>
                                            <h5 className='question'>Information We Collect:</h5>
                                            <p className='description'>We collect the following types of personal information when you use our service:</p>
                                            <p className='description'>Personal Identification Information: Name, email address, phone number, and other contact details.</p>
                                            <p className='description'>Usage Data: Information on how you use our website, including IP address, browser type, and device information</p>
                                            <p className='description'>Cookies Data: Data collected through cookies and similar tracking technologies (see our Cookies Policy).</p>
                                        </li>
                                        <li>
                                            <h5 className='question'>How We Use Your Information:</h5>
                                            <p className='description'>We use the information we collect for the following purposes: </p>
                                            <p className='description'>To provide and maintain our services.</p>
                                            <p className='description'>To improve our website and customer service.</p>
                                            <p className='description'>To communicate with you, including responding to inquiries and sending updates.</p>
                                            <p className='description'>To personalize user experience and tailor content to your preferences. </p>
                                            <p className='description'>To comply with legal obligations and enforce our terms and conditions.</p>
                                        </li>
                                        <li>
                                            <h5 className='question'>How We Share Your Information:</h5>
                                            <p className='description'>We do not sell, trade, or rent your personal information to third parties. However, we may share your information in the following circumstances:</p>
                                            <p className='description'>Service Providers: We may share information with third-party service providers who assist us in operating our website and conducting our business.</p>
                                            <p className='description'>Legal Compliance: We may disclose your information if required by law or in response to a legal request.</p>
                                        </li>
                                        <li>
                                            <h5 className='question'>Data Security:</h5>
                                            <p className='description'>We implement appropriate technical and organizational measures to protect your personal information from unauthorized access, use, or disclosure. However, no internet transmission is entirely secure, and we cannot guarantee absolute security.</p>
                                        </li>
                                        <li>
                                            <h5 className='question'>Your Rights:</h5>
                                            <p className='description'>Depending on your location, you may have the following rights regarding your personal data:</p>
                                            <p className='description'>The right to access, correct, or delete your personal data.</p>
                                            <p className='description'>The right to object to or restrict the processing of your personal data.</p>
                                            <p className='description'>The right to data portability.</p>
                                            <p className='description'>To exercise these rights, please contact us at <Link to="mailto:admin@datapoem.com" className="navigation-text-color">[admin@datapoem.com]</Link>.</p>
                                        </li>
                                        <li>
                                            <h5 className='question'>Changes to This Privacy Statement:</h5>
                                            <p className='description'>We may update this Privacy Statement from time to time. Any changes will be posted on this page, and we encourage you to review it regularly.
                                            </p>
                                        </li>
                                    </ul>

                                    <h5 className='question'>Contact Us:</h5>
                                    <p className='description'>If you have any questions or concerns regarding this Privacy Statement, please contact us at <Link to="mailto:admin@datapoem.com" className="navigation-text-color">[admin@datapoem.com]</Link> .</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PrivacyComponent
